import colorData from './colorData.json';

class ColorService {
  constructor() {
    this.colorData = colorData;
    this.seasons = Object.keys(this.colorData.seasons);
  }

  getSeasonalPalette(season) {
    return this.colorData.seasons[season.toLowerCase()];
  }

  getColorsByRole(season, role) {
    const palette = this.getSeasonalPalette(season);
    return palette[role] || [];
  }

  getExpandedPalette(season) {
    const palette = this.getSeasonalPalette(season);
    return {
      core: palette.core || [],
      extended: palette.extended || [],
      accent: palette.accent || [],
      neutral: palette.neutral || [],
      metallic: palette.metallic || []
    };
  }
}

const colorService = new ColorService();
export default colorService;
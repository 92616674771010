import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { AlertCircle, CheckCircle, EyeIcon, EyeOffIcon, User, Mail, Key, Palette } from 'lucide-react';

const SignUpPage = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: { full_name: fullName },
          emailRedirectTo: `${window.location.origin}/verify-email`
        },
      });

      if (error) throw error;

      if (data?.user) {
        setSuccess('Sign up successful! Please check your email to verify your account.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-[#f5f5f5] items-center justify-center p-4">
      <Card className="w-full max-w-md rounded-2xl shadow-xl bg-white overflow-hidden">
      <CardHeader className="pb-6 pt-10 px-8">
  <h1 className="text-medium font-bold text-center text-gray-800 mb-4">
    The #1 Professional AI Personal Image Assistant
  </h1>
  <div className="flex items-center justify-center mb-8">
    <Palette className="w-10 h-10 text-black mr-3" />
    <CardTitle className="text-4xl font-extrabold text-gray-800">PersonaIndex</CardTitle>
  </div>
  <div className="grid grid-cols-3 gap-4 text-sm text-gray-600 text-center mb-6">
    <div className="flex flex-col items-center p-3 bg-gray-50 rounded-lg">
      <span className="font-semibold text-xl text-vibrant mb-1">10,000+</span>
      <span className="text-xs">Seasonal Color Analyses</span>
    </div>
    <div className="flex flex-col items-center p-3 bg-gray-50 rounded-lg">
      <span className="font-semibold text-xl text-vibrant mb-1">5,000+</span>
      <span className="text-xs">Face Shape Assessments</span>
    </div>
    <div className="flex flex-col items-center p-3 bg-gray-50 rounded-lg">
      <span className="font-semibold text-xl text-vibrant mb-1">8,000+</span>
      <span className="text-xs">Body Type Analyses</span>
    </div>
  </div>
</CardHeader>
        <CardContent className="px-8 py-6">
          {!success ? (
            <form onSubmit={handleSignUp} className="space-y-5">
              <div className="relative">
                <User className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <Input
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  className="pl-10 rounded-lg border-gray-300 bg-white focus:ring-2 focus:ring-vibrant focus:border-transparent"
                />
              </div>
              <div className="relative">
                <Mail className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <Input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="pl-10 rounded-lg border-gray-300 bg-white focus:ring-2 focus:ring-vibrant focus:border-transparent"
                />
              </div>
              <div className="relative">
                <Key className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="pl-10 rounded-lg border-gray-300 bg-white focus:ring-2 focus:ring-vibrant focus:border-transparent pr-10"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                >
                  {showPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                </button>
              </div>
              {error && (
                <div className="text-red-500 flex items-center bg-red-50 p-3 rounded-lg">
                  <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
                  <span className="text-sm">{error}</span>
                </div>
              )}
              <Button 
                type="submit" 
                className="w-full bg-vibrant text-white hover:bg-vibrant-dark transition-colors rounded-full py-3 font-semibold text-lg shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-150"
                disabled={loading}
              >
                {loading ? 'Creating Account...' : 'Sign Up'}
              </Button>
            </form>
          ) : (
            <div className="text-green-600 flex items-center justify-center bg-green-50 p-4 rounded-lg">
              <CheckCircle className="w-6 h-6 mr-2 flex-shrink-0" />
              <p className="text-sm">{success}</p>
            </div>
          )}
          <div className="mt-6 text-center text-sm text-gray-600">
            <button onClick={() => navigate('/terms')} className="text-vibrant hover:underline font-medium">Terms</button>
            {' • '}
            <button onClick={() => navigate('/privacy')} className="text-vibrant hover:underline font-medium">Privacy Policy</button>
          </div>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Already have an account? <button onClick={() => navigate('/signin')} className="text-vibrant hover:underline font-semibold">Sign In</button>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SignUpPage;

import React, { useState, useEffect } from 'react';
import { Upload, AlertCircle, Trash2, Download, Info } from 'lucide-react';
import { Button } from './ui/Button';
import AnalyzeButton from './AnalyzeButton';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';
import { Card, CardTitle, CardContent } from './ui/Card';
import { Separator } from "./ui/Separator";
import { Tooltip } from 'react-tooltip';
import jsPDF from 'jspdf';
import { supabase } from './supabaseClient';

const BodyTypeAnalysis = () => {
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        let interval;
        if (isLoading) {
          const startTime = Date.now();
          const expectedDuration = 5000;
          
          interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min((elapsedTime / expectedDuration) * 100, 95);
            setLoadingProgress(progress);
          }, 50);
        } else {
          setLoadingProgress(0);
        }
        return () => clearInterval(interval);
    }, [isLoading]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setResult(null);
        setError('');
    };

    const handleRemoveImage = () => {
        setFile(null);
        setResult(null);
        setError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }
    
        setIsLoading(true);
        setError('');
        setResult(null);
    
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            // Get the current user's ID
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                throw new Error('User not authenticated');
            }
            
            // Append the user_id to the formData
            formData.append('user_id', user.id);
    
            const response = await fetch('/api/analyze-body-type', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An error occurred while analyzing the image');
            }
    
            const data = await response.json();
            
            if (data.warning) {
                console.warn(data.warning);
                // Optionally, you can display this warning to the user
            }
    
            setResult(data.analysis);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadAndSavePDF = async () => {
        const doc = new jsPDF();
        
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text("Body Type Analysis", 10, 10);
    
        doc.setFontSize(14);
        doc.setFont('helvetica', 'normal');
        doc.text(`Your Kibbe Body Type: ${result.body_type}`, 10, 20);
    
        doc.setFontSize(12);
        doc.text("Clothing Recommendations:", 10, 30);
    
        result.clothing_recommendations.forEach((item, index) => {
            doc.text(`• ${item.item}: ${item.description}`, 15, 40 + index * 10);
        });
    
        const accessoriesStartY = 40 + result.clothing_recommendations.length * 10 + 10;
        doc.text("Accessories Recommendations:", 10, accessoriesStartY);
    
        result.accessory_recommendations.forEach((item, index) => {
            doc.text(`• ${item.item}: ${item.description}`, 15, accessoriesStartY + 10 + index * 10);
        });
    
        const analysisDetailsStartY = accessoriesStartY + 10 + result.accessory_recommendations.length * 10 + 10;
        doc.text("Analysis Summary:", 10, analysisDetailsStartY);
        const splitDetails = doc.splitTextToSize(result.analysis_summary, 180);
        doc.text(splitDetails, 15, analysisDetailsStartY + 10);
    
        const pdfBlob = doc.output('blob');
        const filename = `body_type_analysis_${Date.now()}.pdf`;

        try {
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            if (userError) throw userError;

            const { error: sessionError } = await supabase.auth.refreshSession();
            if (sessionError) {
                console.error('Error refreshing session:', sessionError);
                throw sessionError;
            }

            const { error: uploadError } = await supabase.storage
                .from('user_reports')
                .upload(filename, pdfBlob, {
                    contentType: 'application/pdf'
                });

            if (uploadError) throw uploadError;

            const { error: insertError } = await supabase
                .from('user_reports')
                .insert({
                    user_id: user?.id,
                    file_name: filename,
                    report_type: 'body_type_analysis',
                    created_at: new Date().toISOString()
                });

            if (insertError) throw insertError;

        } catch (error) {
            console.error('Error saving PDF:', error);
            setError(`Error saving PDF: ${error.message}`);
        }

        doc.save('Body_Type_Analysis.pdf');
    };

    

    return (
        <div className="h-full bg-gray-100 flex flex-col items-center justify-center p-4 font-['League_Spartan',sans-serif]">
            <style>
                {`@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');`}
            </style>
            <Card className="w-full max-w-2xl shadow-lg rounded-2xl overflow-hidden bg-white">
                <CardContent className="p-10">
                    <div className="flex justify-between items-center mb-10">
                        <CardTitle className="text-4xl font-extrabold text-gray-800">Body Type Analysis</CardTitle>
                        <Button variant="ghost" size="icon" className="rounded-full hover:bg-gray-100" data-tooltip-id="tooltip" data-tooltip-content="For best results, use a full-body photo in form-fitting clothing.">
                            <Info className="h-6 w-6 text-gray-500" />
                        </Button>
                        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
                    </div>

                    {!file ? (
                        <div className="border-2 border-dashed border-gray-300 rounded-2xl p-10 text-center mb-10">
                            <Upload className="mx-auto h-12 w-12 text-gray-400 mb-4" />
                            <p className="text-gray-600 mb-2">Select or capture a full-body image</p>
                            <p className="text-sm text-gray-400 mb-4">Image file up to 10MB</p>
                            <div className="flex justify-center">
                                <Button 
                                    onClick={() => document.getElementById('fileInput').click()} 
                                    className="bg-white text-gray-800 border border-gray-300 hover:bg-gray-100 rounded-full px-6 py-2"
                                >
                                    Choose Image 
                                </Button>
                            </div>
                            <input 
                                id="fileInput" 
                                type="file" 
                                className="hidden" 
                                onChange={handleFileChange} 
                                accept="image/*" 
                            />
                        </div>
                    ) : (
                        <div className="space-y-4 mb-10">
                            <img 
                                src={URL.createObjectURL(file)} 
                                alt="Preview" 
                                className="w-full h-auto rounded-lg border-4 border-green-400 object-cover"
                            />
                            <div className="flex justify-center">
                                <Button 
                                    onClick={handleRemoveImage}
                                    variant="outline"
                                    className="text-red-500 hover:bg-red-50 rounded-full px-6 py-2 remove-button"
                                >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Remove Image
                                </Button>
                            </div>
                        </div>
                    )}

                    <AnalyzeButton
                        isLoading={isLoading}
                        loadingProgress={loadingProgress}
                        onClick={handleSubmit}
                        disabled={!file || isLoading}
                    />

                    {error && (
                        <Alert variant="destructive" className="mb-10 rounded-xl">
                            <AlertCircle className="h-4 w-4" />
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {result && (
                        <div className="p-10 bg-gray-50 rounded-2xl shadow-lg">
                            <Separator className="mb-8" />
                            <CardTitle className="text-4xl font-extrabold mb-6 text-center text-gray-800" style={{ fontFamily: 'League Spartan, sans-serif', fontWeight: 900 }}>
                                Your Kibbe Body Type: <span className="text-indigo-600">{result.body_type}</span>
                            </CardTitle>

                            <div className="p-6 mb-6 border rounded-xl bg-white">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Clothing Recommendations:</h3>
                                <ul className="list-disc pl-5">
                                    {result.clothing_recommendations.map((item, index) => (
                                        <li key={index} className="text-gray-800 mb-2">
                                            <span className="font-semibold">{item.item}:</span> {item.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="p-6 border rounded-xl bg-white mb-10">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Accessories Recommendations:</h3>
                                <ul className="list-disc pl-5">
                                    {result.accessory_recommendations.map((item, index) => (
                                        <li key={index} className="text-gray-800 mb-2">
                                            <span className="font-semibold">{item.item}:</span> {item.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="p-6 border rounded-xl bg-white mb-10">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Analysis Summary:</h3>
                                <p className="text-sm text-gray-800 whitespace-pre-wrap">{result.analysis_summary}</p>
                            </div>

                            <Button 
                                onClick={downloadAndSavePDF}
                                className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded-full py-3"
                            >
                                <Download className="w-4 h-4 mr-2" />
                                Download Analysis PDF
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default BodyTypeAnalysis;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const VerifyEmailPage = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Extract the access token from the URL fragment
        const hashParams = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = hashParams.get("access_token");

        if (!accessToken) {
          setVerificationStatus('No access token found. Please try the verification link again.');
          return;
        }

        // Set the access token in Supabase
        const { error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: accessToken,
        });

        if (error) throw error;

        // If successful, get the user
        const { data: { user }, error: userError } = await supabase.auth.getUser();

        if (userError) throw userError;

        if (user) {
          setVerificationStatus('Email verified successfully! Redirecting...');
          setTimeout(() => navigate('/dashboard'), 2000);
        } else {
          throw new Error('User not found after verification');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus(`Verification failed: ${error.message}`);
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-4">Email Verification</h2>
        <p className="text-center text-gray-600">{verificationStatus}</p>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
import React, { useState } from 'react';
import { Button } from './ui/Button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/Card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/Select';
import { Slider } from './ui/Slider';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/ToolTip';

const seasonColors = {
        Spring: [
          { name: 'Peach Pink', hex: '#FFB7B2' },
          { name: 'Warm Coral', hex: '#FF9AA2' },
          { name: 'Soft Apricot', hex: '#FFDAC1' },
          { name: 'Light Moss Green', hex: '#E2F0CB' },
          { name: 'Mint', hex: '#B5EAD7' },
          { name: 'Periwinkle', hex: '#C7CEEA' },
          { name: 'Warm Red', hex: '#FF6B6B' },
          { name: 'Turquoise', hex: '#4ECDC4' },
          { name: 'Sky Blue', hex: '#45B7D1' },
          { name: 'Sunshine Yellow', hex: '#F8E16C' },
          { name: 'Bright Green', hex: '#7AE582' },
          { name: 'Warm Orange', hex: '#FFA07A' },
          { name: 'Light Lavender', hex: '#E6E6FA' },
          { name: 'Bright Pink', hex: '#FF69B4' },
          { name: 'Golden Yellow', hex: '#FFD700' }
        ],
        Summer: [
          { name: 'Soft Pink', hex: '#FFC0CB' },
          { name: 'Light Pink', hex: '#FFB6C1' },
          { name: 'Rose Pink', hex: '#FF69B4' },
          { name: 'Plum', hex: '#DDA0DD' },
          { name: 'Thistle', hex: '#D8BFD8' },
          { name: 'Lavender', hex: '#E6E6FA' },
          { name: 'Powder Blue', hex: '#B0E0E6' },
          { name: 'Light Sky Blue', hex: '#87CEFA' },
          { name: 'Light Blue', hex: '#ADD8E6' },
          { name: 'Alice Blue', hex: '#F0F8FF' },
          { name: 'Mint Cream', hex: '#F5FFFA' },
          { name: 'Sea Shell', hex: '#FFF5EE' },
          { name: 'Linen', hex: '#FAF0E6' },
          { name: 'Gainsboro', hex: '#DCDCDC' },
          { name: 'Light Steel Blue', hex: '#B0C4DE' }
        ],
        Autumn: [
          { name: 'Chocolate', hex: '#D2691E' },
          { name: 'Peru', hex: '#CD853F' },
          { name: 'Burlywood', hex: '#DEB887' },
          { name: 'Tan', hex: '#D2B48C' },
          { name: 'Sandy Brown', hex: '#F4A460' },
          { name: 'Saddle Brown', hex: '#8B4513' },
          { name: 'Sienna', hex: '#A0522D' },
          { name: 'Olive Drab', hex: '#6B8E23' },
          { name: 'Dark Olive Green', hex: '#556B2F' },
          { name: 'Dark Sea Green', hex: '#8FBC8F' },
          { name: 'Goldenrod', hex: '#DAA520' },
          { name: 'Dark Goldenrod', hex: '#B8860B' },
          { name: 'Rosy Brown', hex: '#BC8F8F' },
          { name: 'Indian Red', hex: '#CD5C5C' },
          { name: 'Dark Khaki', hex: '#BDB76B' }
        ],
        Winter: [
          { name: 'Navy', hex: '#000080' },
          { name: 'Medium Blue', hex: '#0000CD' },
          { name: 'Royal Blue', hex: '#4169E1' },
          { name: 'Dodger Blue', hex: '#1E90FF' },
          { name: 'Deep Sky Blue', hex: '#00BFFF' },
          { name: 'Sky Blue', hex: '#87CEEB' },
          { name: 'Light Steel Blue', hex: '#B0C4DE' },
          { name: 'Slate Gray', hex: '#708090' },
          { name: 'Light Slate Gray', hex: '#778899' },
          { name: 'Silver', hex: '#C0C0C0' },
          { name: 'Crimson', hex: '#DC143C' },
          { name: 'Deep Pink', hex: '#FF1493' },
          { name: 'Medium Violet Red', hex: '#C71585' },
          { name: 'Purple', hex: '#800080' },
          { name: 'Indigo', hex: '#4B0082' }
        ]
};

const SeasonalColorPaletteGenerator = () => {
    const [selectedSeason, setSelectedSeason] = useState('');
    const [paletteSize, setPaletteSize] = useState(8);
    const [generatedPalette, setGeneratedPalette] = useState([]);
  
    const handleSeasonChange = (value) => {
      setSelectedSeason(value);
      generatePalette(value, paletteSize);
    };
  
    const handlePaletteSizeChange = (value) => {
      setPaletteSize(value[0]);
      generatePalette(selectedSeason, value[0]);
    };
  
    const generatePalette = (season, size) => {
      if (!season) return;
      const allColors = seasonColors[season];
      const shuffled = [...allColors].sort(() => 0.5 - Math.random());
      setGeneratedPalette(shuffled.slice(0, size));
    };
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
    };
  
    return (
      <Card className="w-full max-w-3xl mx-auto">
        <CardHeader>
          <CardTitle>Seasonal Color Palette Generator</CardTitle>
          <CardDescription>Select your season and customize your color palette</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <Select onValueChange={handleSeasonChange}>
              <SelectTrigger className="w-full bg-background border-input">
                <SelectValue placeholder="Select your season" />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(seasonColors).map((season) => (
                  <SelectItem key={season} value={season}>{season}</SelectItem>
                ))}
              </SelectContent>
            </Select>
  
            <div>
              <label className="block text-sm font-medium mb-2">
                Palette Size: {paletteSize}
              </label>
              <Slider
                min={3}
                max={15}
                step={1}
                value={[paletteSize]}
                onValueChange={handlePaletteSizeChange}
                className="w-full"
              />
            </div>
  
            {generatedPalette.length > 0 && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">Your Color Palette:</h3>
                <div className="flex flex-wrap gap-4">
                  <TooltipProvider>
                    {generatedPalette.map((color, index) => (
                      <Tooltip key={index}>
                        <TooltipTrigger>
                          <div
                            className="w-16 h-16 rounded-full cursor-pointer shadow-md hover:shadow-lg transition-shadow"
                            style={{ backgroundColor: color.hex }}
                            onClick={() => copyToClipboard(color.hex)}
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="font-semibold">{color.name}</p>
                          <p>{color.hex}</p>
                          <p className="text-xs text-muted-foreground">Click to copy hex</p>
                        </TooltipContent>
                      </Tooltip>
                    ))}
                  </TooltipProvider>
                </div>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter>
          <Button onClick={() => generatePalette(selectedSeason, paletteSize)} className="w-full">
            Regenerate Palette
          </Button>
        </CardFooter>
      </Card>
    );
  };
  
  export default SeasonalColorPaletteGenerator;
import React, { useState, useEffect } from 'react';
import { Upload, AlertCircle, Trash2, Download, Info} from 'lucide-react';
import { Button } from './ui/Button';
import AnalyzeButton from './AnalyzeButton';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';
import { Card, CardTitle, CardContent } from './ui/Card';
import { Tooltip } from 'react-tooltip';
import jsPDF from 'jspdf';
import { supabase } from './supabaseClient';




const MeasurementBadge = ({ measurement }) => {
    const colors = {
        'VERY_NARROW': 'bg-red-100 text-red-800',
        'NARROW': 'bg-orange-100 text-orange-800',
        'SLIGHTLY_NARROW': 'bg-yellow-100 text-yellow-800',
        'BALANCED': 'bg-green-100 text-green-800',
        'SLIGHTLY_WIDE': 'bg-blue-100 text-blue-800',
        'WIDE': 'bg-indigo-100 text-indigo-800',
        'VERY_WIDE': 'bg-purple-100 text-purple-800'
    };

    return (
        <span className={`px-2 py-1 rounded-full text-sm ${colors[measurement] || 'bg-gray-100 text-gray-800'}`}>
            {measurement.replace('_', ' ')}
        </span>
    );
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const FaceShapeAnalysis = () => {
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        let interval;
        if (isLoading) {
            const startTime = Date.now();
            const expectedDuration = 40000;
            
            interval = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                const progress = Math.min((elapsedTime / expectedDuration) * 100, 95);
                setLoadingProgress(progress);
            }, 50);
        } else {
            setLoadingProgress(0);
        }
        return () => clearInterval(interval);
    }, [isLoading]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setResult(null);
        setError('');
    };

    const handleRemoveImage = () => {
        setFile(null);
        setResult(null);
        setError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }
    
        setIsLoading(true);
        setError('');
        setResult(null);
    
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            // Get the current user's ID
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                throw new Error('User not authenticated');
            }
            
            formData.append('user_id', user.id);

            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 40000);

            const response = await fetch('/api/analyze-face-shape', {
                method: 'POST',
                body: formData,
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            if (!response.ok) {
                let errorMessage = 'An error occurred while analyzing the image';
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.error || errorMessage;
                } catch (e) {
                    // If JSON parsing fails, use status text
                    errorMessage = response.statusText || errorMessage;
                }
                throw new Error(errorMessage);
            }

            const data = await response.json();
            
            if (data.warning) {
                console.warn(data.warning);
            }

            setResult(data.analysis);
        } catch (error) {
            console.error('Error:', error);
            if (error.name === 'AbortError') {
                setError('The analysis is taking longer than expected. Please try again.');
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const downloadAndSavePDF = async () => {
        const doc = new jsPDF();
        let yPosition = 10;
        const pageWidth = doc.internal.pageSize.width;
        const margin = 10;
        const maxWidth = pageWidth - (margin * 2);

        // Helper function for text wrapping and positioning
        const addWrappedText = (text, y, fontSize = 12, isBold = false) => {
            doc.setFontSize(fontSize);
            doc.setFont('helvetica', isBold ? 'bold' : 'normal');
            const lines = doc.splitTextToSize(text, maxWidth);
            doc.text(lines, margin, y);
            return y + (lines.length * (fontSize * 0.4)) + 5;
        };

        // Title
        yPosition = addWrappedText("Face Shape Analysis", yPosition, 18, true);
        yPosition += 5;

        // Face Shape Section
        yPosition = addWrappedText(`Primary Face Shape: ${result.face_shape.primary_shape}`, yPosition, 14, true);
        
        if (result.face_shape.secondary_shapes?.length > 0) {
            yPosition = addWrappedText(`Secondary Influences: ${result.face_shape.secondary_shapes.join(', ')}`, yPosition);
        }
        
        yPosition = addWrappedText(`Confidence Level: ${result.face_shape.confidence_level}`, yPosition);
        
        if (result.face_shape.narrative_analysis) {
            yPosition += 5;
            yPosition = addWrappedText("Analysis:", yPosition, 12, true);
            yPosition = addWrappedText(result.face_shape.narrative_analysis, yPosition);
        }
        yPosition += 10;

        // Proportions Section
        yPosition = addWrappedText("Facial Proportions", yPosition, 14, true);
        
        // Thirds Analysis
        yPosition = addWrappedText("Thirds Analysis:", yPosition, 12, true);
        Object.entries(result.proportions.thirds_analysis).forEach(([third, measurement]) => {
            yPosition = addWrappedText(`${third}: ${measurement}`, yPosition);
        });

        // Eye Spacing
        yPosition += 5;
        yPosition = addWrappedText("Eye Spacing:", yPosition, 12, true);
        yPosition = addWrappedText(result.proportions.eye_spacing.description, yPosition);
        
        if (result.proportions.narrative_summary) {
            yPosition += 5;
            yPosition = addWrappedText(result.proportions.narrative_summary, yPosition);
        }
        yPosition += 10;

        // Check if we need a new page
        if (yPosition > doc.internal.pageSize.height - 50) {
            doc.addPage();
            yPosition = 10;
        }

        // Facial Features Section
        yPosition = addWrappedText("Facial Features", yPosition, 14, true);
        
        // Eyes & Eyebrows
        yPosition = addWrappedText("Eyes & Eyebrows:", yPosition, 12, true);
        yPosition = addWrappedText(result.facial_features.eyes_and_eyebrows.shape, yPosition);
        if (result.facial_features.eyes_and_eyebrows.narrative_description) {
            yPosition = addWrappedText(result.facial_features.eyes_and_eyebrows.narrative_description, yPosition);
        }

        // Nose & Mouth
        yPosition += 5;
        yPosition = addWrappedText("Nose & Mouth:", yPosition, 12, true);
        yPosition = addWrappedText(`Nose Width: ${result.facial_features.nose_and_mouth.nose_width.measurement}`, yPosition);
        yPosition = addWrappedText(`Philtrum: ${result.facial_features.nose_and_mouth.philtrum.measurement}`, yPosition);
        yPosition = addWrappedText(`Lip Width: ${result.facial_features.nose_and_mouth.lip_width.measurement}`, yPosition);
        yPosition = addWrappedText(`Lip Corners: ${capitalizeFirstLetter(result.facial_features.nose_and_mouth.lip_corners.angle)}`, yPosition);
        if (result.facial_features.nose_and_mouth.narrative_analysis) {
            yPosition = addWrappedText(result.facial_features.nose_and_mouth.narrative_analysis, yPosition);
        }
        yPosition += 10;

        // Check if we need another new page
        if (yPosition > doc.internal.pageSize.height - 50) {
            doc.addPage();
            yPosition = 10;
        }

        // Visual Characteristics Section
        yPosition = addWrappedText("Visual Characteristics", yPosition, 14, true);
        
        // Weight
        yPosition = addWrappedText(`Visual Weight: ${result.visual_characteristics.weight.overall_weight}`, yPosition, 12, true);
        
        yPosition = addWrappedText("Enhancing Factors:", yPosition, 12, true);
        result.visual_characteristics.weight.enhancing_factors.forEach(factor => {
            yPosition = addWrappedText(`• ${factor}`, yPosition);
        });

        yPosition = addWrappedText("Reducing Factors:", yPosition, 12, true);
        result.visual_characteristics.weight.reducing_factors.forEach(factor => {
            yPosition = addWrappedText(`• ${factor}`, yPosition);
        });

        if (result.visual_characteristics.weight.narrative_impact) {
            yPosition += 5;
            yPosition = addWrappedText(result.visual_characteristics.weight.narrative_impact, yPosition);
        }

        // Inherent Impression
        yPosition += 5;
        yPosition = addWrappedText("Inherent Impression:", yPosition, 12, true);
        yPosition = addWrappedText(
            `Primary Characteristics: ${result.visual_characteristics.inherent_impression.primary_characteristics.join(', ')}`, 
            yPosition
        );
        
        if (result.visual_characteristics.inherent_impression.detailed_analysis) {
            yPosition += 5;
            yPosition = addWrappedText(result.visual_characteristics.inherent_impression.detailed_analysis, yPosition);
        }

        // Save PDF
        const pdfBlob = doc.output('blob');
        const filename = `face_shape_analysis_${Date.now()}.pdf`;

        try {
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            if (userError) throw userError;

            const { error: sessionError } = await supabase.auth.refreshSession();
            if (sessionError) throw sessionError;

            const { error: uploadError } = await supabase.storage
                .from('user_reports')
                .upload(filename, pdfBlob, {
                    contentType: 'application/pdf'
                });

            if (uploadError) throw uploadError;

            const { error: insertError } = await supabase
                .from('user_reports')
                .insert({
                    user_id: user?.id,
                    file_name: filename,
                    report_type: 'face_shape_analysis',
                    created_at: new Date().toISOString()
                });

            if (insertError) throw insertError;

        } catch (error) {
            console.error('Error saving PDF:', error);
            setError(`Error saving PDF: ${error.message}`);
        }

        doc.save('Face_Shape_Analysis.pdf');
    };

    return (
        <div className="h-full bg-gray-100 flex flex-col items-center justify-center p-4 font-['League_Spartan',sans-serif]">
            <style>
                {`@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');`}
            </style>
            <Card className="w-full max-w-3xl shadow-lg rounded-2xl overflow-hidden bg-white">
                <CardContent className="p-10">
                    <div className="flex justify-between items-center mb-10">
                        <CardTitle className="text-4xl font-extrabold text-gray-800">Face Shape Analysis</CardTitle>
                        <Button variant="ghost" size="icon" className="rounded-full hover:bg-gray-100" data-tooltip-id="tooltip" data-tooltip-content="For best results, use a front-facing photo with a neutral expression.">
                            <Info className="h-6 w-6 text-gray-500" />
                        </Button>
                        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
                    </div>

                    {!file ? (
                        <div className="border-2 border-dashed border-gray-300 rounded-2xl p-10 text-center mb-10">
                            <Upload className="mx-auto h-12 w-12 text-gray-400 mb-4" />
                            <p className="text-gray-600 mb-2">Select or capture a face photo</p>
                            <p className="text-sm text-gray-400 mb-4">Image file up to 10MB</p>
                            <div className="flex justify-center">
                                <Button 
                                    onClick={() => document.getElementById('fileInput').click()} 
                                    className="bg-white text-gray-800 border border-gray-300 hover:bg-gray-100 rounded-full px-6 py-2"
                                >
                                    Choose Image 
                                </Button>
                            </div>
                            <input 
                                id="fileInput" 
                                type="file" 
                                className="hidden" 
                                onChange={handleFileChange} 
                                accept="image/*" 
                            />
                        </div>
                    ) : (
                        <div className="space-y-4 mb-10">
                            <img 
                                src={URL.createObjectURL(file)} 
                                alt="Preview" 
                                className="w-full h-auto rounded-lg border-4 border-green-400 object-cover"
                            />
                            <div className="flex justify-center">
                                <Button 
                                    onClick={handleRemoveImage}
                                    variant="outline"
                                    className="text-red-500 hover:bg-red-50 rounded-full px-6 py-2 remove-button"
                                >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Remove Image
                                </Button>
                            </div>
                        </div>
                    )}

                    <AnalyzeButton
                        isLoading={isLoading}
                        loadingProgress={loadingProgress}
                        onClick={handleSubmit}
                        disabled={!file || isLoading}
                    />

{error && (
                <Alert variant="destructive" className="mb-10 rounded-xl">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

{result && (
    <div className="p-8 bg-gradient-to-b from-gray-50 to-white rounded-2xl shadow-lg">
        {/* Header Section */}
        <div className="mb-10 text-center">
            <div className="inline-flex items-center justify-center p-1 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                <div className="px-6 py-2 rounded-full bg-white">
                    <span className="text-lg font-semibold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                        Analysis Complete
                    </span>
                </div>
            </div>
        </div>

        {/* Primary Results Section */}
        <div className="mb-8 p-6 bg-white rounded-xl shadow-sm border border-gray-100">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center p-4 rounded-lg bg-gradient-to-b from-gray-50 to-white">
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Primary Shape</h3>
                    <p className="text-2xl font-bold text-gray-800">{result.face_shape.primary_shape}</p>
                </div>
                <div className="text-center p-4 rounded-lg bg-gradient-to-b from-gray-50 to-white">
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Confidence Level</h3>
                    <p className="text-2xl font-bold text-gray-800">{result.face_shape.confidence_level}</p>
                </div>
                <div className="text-center p-4 rounded-lg bg-gradient-to-b from-gray-50 to-white">
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Secondary Influences</h3>
                    <p className="text-2xl font-bold text-gray-800">
                        {result.face_shape.secondary_shapes?.length > 0 
                            ? result.face_shape.secondary_shapes.join(', ') 
                            : 'None'}
                    </p>
                </div>
            </div>
            {result.face_shape.narrative_analysis && (
                <div className="mt-6 p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg">
                    <p className="text-sm text-gray-700 leading-relaxed">
                        {result.face_shape.narrative_analysis}
                    </p>
                </div>
            )}
        </div>

        {/* Proportions Analysis */}
        <div className="mb-8">
            <div className="flex items-center space-x-3 mb-4">
                <div className="h-8 w-8 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center text-white font-bold">
                    1
                </div>
                <h2 className="text-xl font-bold text-gray-800">Proportions Analysis</h2>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-sm font-medium text-gray-500 mb-4">Horizontal Description</h3>
                <p className="text-sm text-gray-700 mb-4">{result.proportions.horizontal_description}</p>
                
                <h3 className="text-sm font-medium text-gray-500 mb-4">Vertical Description</h3>
                <p className="text-sm text-gray-700 mb-4">{result.proportions.vertical_description}</p>

                <h3 className="text-sm font-medium text-gray-500 mb-4">Thirds Analysis</h3>
                <div className="space-y-3">
                    {Object.entries(result.proportions.thirds_analysis).map(([third, measurement]) => (
                        <div key={third} className="flex items-center justify-between">
                            <span className="text-sm font-medium capitalize">{third.replace(/_/g, ' ')}</span>
                            <MeasurementBadge measurement={measurement} />
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-sm font-medium text-gray-500 mb-4">Eye Spacing</h3>
                <div className="flex items-center justify-between mb-3">
                    <span className="text-sm">{result.proportions.eye_spacing.description}</span>
                    <MeasurementBadge measurement={result.proportions.eye_spacing.measurement} />
                </div>
                {result.proportions.narrative_summary && (
                    <p className="text-sm text-gray-600 mt-4 p-3 bg-gray-50 rounded-lg">
                        {result.proportions.narrative_summary}
                    </p>
                )}
            </div>
        </div>

        {/* Facial Features Analysis */}
        <div className="space-y-6">
            <div className="flex items-center space-x-3 mb-4">
                <div className="h-8 w-8 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center text-white font-bold">
                    2
                </div>
                <h2 className="text-xl font-bold text-gray-800">Facial Features</h2>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-sm font-medium text-gray-500 mb-4">Eyes & Eyebrows</h3>
                <div className="space-y-3">
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Iris Characteristics</span>
                        <span className="text-sm">{result.facial_features.eyes_and_eyebrows.iris_characteristics.join(', ')}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Eye Shape</span>
                        <span className="text-sm">{result.facial_features.eyes_and_eyebrows.eye_shape}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Width to Length Ratio</span>
                        <MeasurementBadge measurement={result.facial_features.eyes_and_eyebrows.width_to_length_ratio.measurement} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Eye Angle</span>
                        <MeasurementBadge measurement={result.facial_features.eyes_and_eyebrows.angle.measurement} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Eyebrow Shape</span>
                        <span className="text-sm">{result.facial_features.eyes_and_eyebrows.eyebrow_shape}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Eyebrow Thickness</span>
                        <MeasurementBadge measurement={result.facial_features.eyes_and_eyebrows.eyebrow_thickness} />
                    </div>
                </div>
                {result.facial_features.eyes_and_eyebrows.visual_impact && (
                    <p className="text-sm text-gray-600 mt-4 p-3 bg-gray-50 rounded-lg">
                        {result.facial_features.eyes_and_eyebrows.visual_impact.join(', ')}
                    </p>
                )}
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-sm font-medium text-gray-500 mb-4">Nose & Mouth</h3>
                <div className="space-y-3">
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Nose Width</span>
                        <MeasurementBadge measurement={result.facial_features.nose_and_mouth.nose_width.measurement} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Philtrum</span>
                        <MeasurementBadge measurement={result.facial_features.nose_and_mouth.philtrum.measurement} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Lip Width</span>
                        <MeasurementBadge measurement={result.facial_features.nose_and_mouth.lip_width.measurement} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Lip Corners</span>
                        <span className="text-sm">{capitalizeFirstLetter(result.facial_features.nose_and_mouth.lip_corners.angle)}</span>
                    </div>
                </div>
                {result.facial_features.nose_and_mouth.narrative_analysis && (
                    <p className="text-sm text-gray-600 mt-4 p-3 bg-gray-50 rounded-lg">
                        {result.facial_features.nose_and_mouth.narrative_analysis}
                    </p>
                )}
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-sm font-medium text-gray-500 mb-4">Forehead, Hairline & Cranial Top</h3>
                <div className="space-y-3">
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Hairline Shape</span>
                        <span className="text-sm">{result.facial_features.forehead_hairline_cranial_top.hairline_shape}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Forehead Height</span>
                        <MeasurementBadge measurement={result.facial_features.forehead_hairline_cranial_top.forehead_height} />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm">Cranial Top</span>
                        <MeasurementBadge measurement={result.facial_features.forehead_hairline_cranial_top.cranial_top} />
                    </div>
                </div>
                {result.facial_features.forehead_hairline_cranial_top.narrative_analysis && (
                    <p className="text-sm text-gray-600 mt-4 p-3 bg-gray-50 rounded-lg">
                        {result.facial_features.forehead_hairline_cranial_top.narrative_analysis}
                    </p>
                )}
            </div>
        </div>

        {/* Visual Characteristics */}
        <div className="mb-8">
            <div className="flex items-center space-x-3 mb-6">
                <div className="h-8 w-8 rounded-full bg-gradient-to-r from-pink-500 to-red-500 flex items-center justify-center text-white font-bold">
                    3
                </div>
                <h2 className="text-xl font-bold text-gray-800">Visual Characteristics</h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-500 mb-4">Weight Distribution</h3>
                    <div className="mb-4">
                        <span className="inline-block px-4 py-2 bg-gradient-to-r from-gray-100 to-gray-200 rounded-full text-sm font-medium">
                            {capitalizeFirstLetter(result.visual_characteristics.weight.overall_weight)}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <h4 className="text-xs font-medium text-gray-500 mb-2">Enhancing Factors</h4>
                            <ul className="space-y-1">
                                {result.visual_characteristics.weight.enhancing_factors.map((factor, index) => (
                                    <li key={index} className="text-sm text-gray-700 flex items-center">
                                        <span className="w-1.5 h-1.5 bg-green-400 rounded-full mr-2"></span>
                                        {factor}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-xs font-medium text-gray-500 mb-2">Reducing Factors</h4>
                            <ul className="space-y-1">
                                {result.visual_characteristics.weight.reducing_factors.map((factor, index) => (
                                    <li key={index} className="text-sm text-gray-700 flex items-center">
                                        <span className="w-1.5 h-1.5 bg-red-400 rounded-full mr-2"></span>
                                        {factor}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-500 mb-4">Inherent Impression</h3>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {result.visual_characteristics.inherent_impression.primary_characteristics.map((char, index) => (
                            <span key={index} 
                                  className="px-3 py-1 bg-gradient-to-r from-gray-100 to-gray-200 
                                           rounded-full text-sm font-medium">
                                {char}
                            </span>
                        ))}
                    </div>
                    {result.visual_characteristics.inherent_impression.detailed_analysis && (
                        <p className="text-sm text-gray-600 p-3 bg-gray-50 rounded-lg">
                            {result.visual_characteristics.inherent_impression.detailed_analysis}
                        </p>
                    )}
                </div>
            </div>
        </div>

        {/* Download Button */}
        <Button 
            onClick={downloadAndSavePDF}
            className="w-full bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 
                     hover:from-gray-800 hover:via-gray-700 hover:to-gray-800 
                     text-white rounded-xl py-4 shadow-lg 
                     transform transition-all duration-200 hover:scale-[1.01]"
        >
            <Download className="w-5 h-5 mr-2" />
            Download Complete Analysis PDF
        </Button>
    </div>
)}
                </CardContent>
            </Card>
        </div>
    );
};

export default FaceShapeAnalysis;
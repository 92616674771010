import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/Card';
import { Input } from '../components/ui/Input';
import { Button } from '../components/ui/Button';
import { AlertCircle, CheckCircle } from 'lucide-react';

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      setSuccess(location.state.message);
    }
  }, [location]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
        },
      });

      if (error) throw error;

      setSuccess('Magic link sent! Check your email to sign in.');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-[#f5f5f5] items-center justify-center p-4">
      <Card className="w-full max-w-md rounded-lg shadow-md bg-white">
        <CardHeader className="pb-2">
          <CardTitle className="text-2xl font-bold text-center text-black">Sign In</CardTitle>
        </CardHeader>
        <CardContent>
          {!success ? (
            <form onSubmit={handleSignIn} className="space-y-4">
              <div>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="rounded border-gray-300 bg-white"
                />
              </div>
              {error && (
                <div className="text-red-500 flex items-center bg-red-50 p-3 rounded">
                  <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
                  <span className="text-sm">{error}</span>
                </div>
              )}
              <Button 
                type="submit" 
                className="w-full bg-black text-white hover:bg-vibrant transition-colors rounded py-2 font-semibold"
                disabled={loading}
              >
                {loading ? 'Sending Magic Link...' : 'Send Magic Link'}
              </Button>
            </form>
          ) : (
            <div className="text-green-600 flex items-center justify-center bg-green-50 p-4 rounded">
              <CheckCircle className="w-6 h-6 mr-2 flex-shrink-0" />
              <p className="text-sm">{success}</p>
            </div>
          )}
          <div className="mt-6 text-center text-sm text-gray-600">
            By signing in, you agree to our Terms of Service and Privacy Policy.
          </div>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Don't have an account?{' '}
              <Link to="/signup" className="text-vibrant hover:underline font-semibold">
                Sign up
              </Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SignInPage;
import React, { useState, useEffect } from 'react';
import { validateImage } from '../utils/imageValidation';
import { Upload, Trash2, Download, Info, X } from 'lucide-react';
import { Button } from './ui/Button';
import AnalyzeButton from './AnalyzeButton';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';
import { Card, CardTitle, CardDescription, CardContent } from './ui/Card';
import { Separator } from "./ui/Separator";
import { Tooltip } from 'react-tooltip';
import { generateAnalysisPDF } from '../utils/generateAnalysisPDF';
import { supabase } from './supabaseClient';

const ImageAnalysisApp = () => {
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [showSamplePhoto, setShowSamplePhoto] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        console.log('useEffect triggered, isLoading:', isLoading);
        let interval;
        if (isLoading) {
          const startTime = Date.now();
          const expectedDuration = 9000; // 9 seconds
          
          interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min((elapsedTime / expectedDuration) * 100, 95);
            console.log('Setting progress:', progress);
            setLoadingProgress(progress);
          }, 50);
        } else {
          setLoadingProgress(0);
        }
        return () => clearInterval(interval);
    }, [isLoading]);

    useEffect(() => {
        const fetchUserEmail = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
                setUserEmail(user.email);
            }
        };
        fetchUserEmail();
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const validation = validateImage(selectedFile);

        if (!validation.isValid) {
            setError(validation.error);
            setFile(null);
        } else {
            setFile(selectedFile);
            setResult(null);
            setError('');
        }
    };

    const handleRemoveImage = () => {
        setFile(null);
        setResult(null);
        setError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }
    
        setIsLoading(true);
        setError('');
        setResult(null);
    
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            // Get the current user's ID
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                throw new Error('User not authenticated');
            }
            
            // Append the user_id to the formData
            formData.append('user_id', user.id);
    
            const response = await fetch('/api/analyze', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server error:', errorText);
                throw new Error(`Server error: ${response.status} ${response.statusText}`);
            }
    
            const data = await response.json();
            
            if (data.warning) {
                console.warn(data.warning);
                // Optionally, you can display this warning to the user
            }
    
            if (!data.analysis) {
                throw new Error('No analysis data received from server');
            }
    
            setResult(data.analysis);
        } catch (error) {
            console.error('Error:', error);
            setError(`An error occurred: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadAndSavePDF = async () => {
        const formattedResult = {
            season: result.season,
            subSeason: result.sub_season,
            complementaryPalette: result.complementary_palette.map(color => `${color.hex} - ${color.name}`),
            avoidPalette: result.avoid_palette.map(color => `${color.hex} - ${color.name}`),
            details: result.analysis_summary
        };

        const doc = generateAnalysisPDF('Seasonal Color Analysis', formattedResult, userEmail);
        const pdfBlob = doc.output('blob');
        const filename = `color_analysis_${Date.now()}.pdf`;

        try {
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            
            if (userError) throw userError;

            const { error: sessionError } = await supabase.auth.refreshSession();
            if (sessionError) {
                console.error('Error refreshing session:', sessionError);
                throw sessionError;
            }

            const { error: uploadError } = await supabase.storage
                .from('user_reports')
                .upload(filename, pdfBlob, {
                    contentType: 'application/pdf'
                });

            if (uploadError) throw uploadError;

            const { error: insertError } = await supabase
                .from('user_reports')
                .insert({
                    user_id: user?.id,
                    file_name: filename,
                    report_type: 'color_analysis',
                    created_at: new Date().toISOString()
                });

            if (insertError) throw insertError;

            console.log('PDF saved successfully');
        } catch (error) {
            console.error('Error saving PDF:', error);
            setError(`Error saving PDF: ${error.message}`);
        }

        doc.save('Seasonal_Color_Analysis.pdf');
    };

    console.log('Rendering ImageAnalysisApp, isLoading:', isLoading, 'loadingProgress:', loadingProgress);

    return (
        <div className="h-full bg-gray-100 flex flex-col items-center justify-center p-4 font-['League_Spartan',sans-serif]">
        <style>
          {`@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');`}
        </style>
        <Card className="w-full max-w-2xl shadow-lg rounded-2xl overflow-hidden bg-white">
          <CardContent className="p-10">
            <div className="flex justify-end mb-6">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setShowSamplePhoto(!showSamplePhoto)}
                className="transition-colors duration-200 hover:bg-gray-100"
              >
                Sample Upload
              </Button>
            </div>
            
            {showSamplePhoto && (
              <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-opacity duration-300 ease-in-out opacity-100">
                <div className="relative">
                  <img 
                    src="/images/sample-color-analysis.jpg" 
                    alt="Sample color analysis" 
                    className="max-w-full h-auto rounded-lg border border-gray-300"
                  />
                  <Button
                    variant="ghost"
                    size="sm"
                    className="absolute top-2 right-2 bg-white bg-opacity-70 hover:bg-opacity-100 transition-colors duration-200"
                    onClick={() => setShowSamplePhoto(false)}
                  >
                    <X size={16} />
                  </Button>
                </div>
                <p className="mt-4 text-sm text-gray-700 leading-relaxed">
                  Ensure your photo is taken in natural light with a neutral background. This helps accurately determine your color season.
                </p>
              </div>
            )}
        

                    <div className="flex justify-between items-center mb-10">
                        <CardTitle className="text-4xl font-extrabold text-gray-800">Seasonal Color Analysis</CardTitle>
                        <Button variant="ghost" size="icon" className="rounded-full hover:bg-gray-100" data-tooltip-id="tooltip" data-tooltip-content="For best results, use a high-res photo under indirect sunlight.">
                            <Info className="h-6 w-6 text-gray-500" />
                        </Button>
                        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
                    </div>
    
                    {!file ? (
                        <div className="border-2 border-dashed border-gray-300 rounded-2xl p-10 text-center mb-10">
                            <Upload className="mx-auto h-12 w-12 text-gray-400 mb-4" />
                            <p className="text-gray-600 mb-4">Accepted files: JPG or PNG, up to 10MB</p>
                            <div className="flex justify-center">
                                <Button 
                                    onClick={() => document.getElementById('fileInput').click()} 
                                    className="bg-white text-gray-800 border border-gray-300 hover:bg-gray-100 rounded-full px-6 py-2"
                                >
                                    Choose Image 
                                </Button>
                            </div>
                            <input 
                                id="fileInput" 
                                type="file" 
                                className="hidden" 
                                onChange={handleFileChange} 
                                accept="image/jpeg, image/png" 
                            />
                        </div>
                    ) : (
                        <div className="space-y-4 mb-10">
                            <img 
                                src={URL.createObjectURL(file)} 
                                alt="Preview" 
                                className="w-full h-auto rounded-lg border-4 border-green-400 object-cover"
                            />
                            <div className="flex justify-center">
                                <Button 
                                    onClick={handleRemoveImage}
                                    variant="outline"
                                    className="text-red-500 hover:bg-red-50 rounded-full px-6 py-2 remove-button"
                                >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Remove Image
                                </Button>
                            </div>
                        </div>
                    )}
    
    <AnalyzeButton
                        isLoading={isLoading}
                        loadingProgress={loadingProgress}
                        onClick={handleSubmit}
                        disabled={!file || isLoading}
                    />
    
                    {error && (
                                <Alert variant="destructive" className="mb-10 rounded-xl">
                                    <AlertTitle>Error</AlertTitle>
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}
    
                    {result && (
                        <div className="p-10 bg-gray-50 rounded-2xl shadow-lg">
                            <Separator className="mb-8" />
                            <CardTitle className="text-4xl font-extrabold mb-6 text-center text-gray-800" style={{ fontFamily: 'League Spartan, sans-serif', fontWeight: 900 }}>
                                Your Season: <span className="text-indigo-600">{result.season}</span>
                            </CardTitle>
                            {result.sub_season && (
                                <CardDescription className="text-2xl text-center mb-6">
                                    Sub-season: <span className="font-semibold">{result.sub_season}</span>
                                </CardDescription>
                            )}
    
                            <div className="p-6 mb-6 border rounded-xl bg-white">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Complementary Colors:</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                                    {result.complementary_palette.map((color, index) => (
                                        <div key={index} className="flex flex-col items-center">
                                            <div className="w-16 h-16 rounded-full shadow-lg" style={{ backgroundColor: color.hex }}></div>
                                            <span className="text-sm mt-2 text-center text-gray-800">{color.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
    
                            <div className="p-6 border rounded-xl bg-white mb-10">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Colors to Avoid:</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
                                    {result.avoid_palette.map((color, index) => (
                                        <div key={index} className="flex flex-col items-center">
                                            <div className="w-16 h-16 rounded-full shadow-lg relative" style={{ backgroundColor: color.hex }}>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <div className="w-16 h-0.5 bg-red-500 transform rotate-45"></div>
                                                </div>
                                            </div>
                                            <span className="text-sm mt-2 text-center text-gray-800">{color.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
    
                            <CardDescription className="text-lg text-gray-700 mb-4">Analysis Summary:</CardDescription>
                            <p className="text-sm text-gray-800 whitespace-pre-wrap mb-6">{result.analysis_summary}</p>
    
                            <Button 
                                onClick={downloadAndSavePDF}
                                className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded-full py-3"
                            >
                                <Download className="w-4 h-4 mr-2" />
                                Download Analysis PDF
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default ImageAnalysisApp;
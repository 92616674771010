import jsPDF from 'jspdf';

export const generateAnalysisPDF = (analysisType, result, userEmail) => {
  const doc = new jsPDF();
  let yPos = 20;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 10;
  let pageNum = 1;

  const addWrappedText = (text, x, y, maxWidth, lineHeight) => {
    const lines = doc.splitTextToSize(text, maxWidth);
    for (let i = 0; i < lines.length; i++) {
      if (y > pageHeight - margin) {
        addPageNumber();
        doc.addPage();
        y = 20;
        pageNum++;
      }
      doc.text(lines[i], x, y);
      y += lineHeight;
    }
    return y;
  };

  const addPageNumber = () => {
    doc.setFontSize(10);
    doc.text(`Page ${pageNum}`, pageWidth - 20, pageHeight - 10);
  };

  const addColorBlock = (color, name, x, y) => {
    doc.setFillColor(color);
    doc.rect(x, y, 10, 10, 'F');
    doc.setFont('helvetica', 'normal');
    doc.text(name, x + 15, y + 7);
    return y + 15;
  };

  // Add a subtle background color
  doc.setFillColor(250, 250, 250);
  doc.rect(0, 0, pageWidth, pageHeight, 'F');

  // Title
  doc.setFontSize(24);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(0, 102, 204);
  yPos = addWrappedText(`${analysisType}`, margin, yPos, pageWidth - 2 * margin, 10);

  // User email and analysis date
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor(100, 100, 100);
  yPos = addWrappedText(`Report for: ${userEmail}`, margin, yPos + 5, pageWidth - 2 * margin, 5);
  yPos = addWrappedText(`Analysis Date: ${new Date().toLocaleDateString()}`, margin, yPos + 5, pageWidth - 2 * margin, 5);

  // Analysis Summary
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(0, 0, 0);
  yPos = addWrappedText("Analysis Summary:", margin, yPos + 10, pageWidth - 2 * margin, 7);

  // Color Season and Sub-Season
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  yPos = addWrappedText(`Color Season: ${result.season}`, margin, yPos + 5, pageWidth - 2 * margin, 6);
  if (result.subSeason) {
    doc.setFont('helvetica', 'normal');
    yPos = addWrappedText(`Sub-Season: ${result.subSeason}`, margin, yPos + 5, pageWidth - 2 * margin, 6);
  }

  // Analysis Details
  doc.setFont('helvetica', 'normal');
  yPos = addWrappedText(result.details, margin, yPos + 10, pageWidth - 2 * margin, 6);

  // Add a separator line
  doc.setDrawColor(200, 200, 200);
  doc.setLineWidth(0.2);
  doc.line(margin, yPos + 5, pageWidth - margin, yPos + 5);

  // Color Palettes with blocks
  addPageNumber();
  doc.addPage();
  yPos = 20;
  pageNum++;

  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(0, 0, 0);
  yPos = addWrappedText("Complementary Color Palette:", margin, yPos, pageWidth - 2 * margin, 7);

  result.complementaryPalette.forEach((colorEntry) => {
    const [hex, name] = colorEntry.split(' - ');
    yPos = addColorBlock(hex, name, margin, yPos + 5);
    if (yPos > pageHeight - 30) {
      addPageNumber();
      doc.addPage();
      yPos = 20;
      pageNum++;
    }
  });

  yPos += 10;
  doc.setFont('helvetica', 'bold');
  yPos = addWrappedText("Colors to Avoid:", margin, yPos, pageWidth - 2 * margin, 7);

  result.avoidPalette.forEach((colorEntry) => {
    const [hex, name] = colorEntry.split(' - ');
    yPos = addColorBlock(hex, name, margin, yPos + 5);
    if (yPos > pageHeight - 30) {
      addPageNumber();
      doc.addPage();
      yPos = 20;
      pageNum++;
    }
  });

  // What does this mean?
  yPos += 15;
  doc.setFont('helvetica', 'bold');
  yPos = addWrappedText("What does this mean?", margin, yPos, pageWidth - 2 * margin, 7);
  doc.setFont('helvetica', 'normal');
  yPos = addWrappedText("Your color season is based on your skin undertone, hair, and eye color. It helps determine which colors complement your natural features best.", margin, yPos + 5, pageWidth - 2 * margin, 6);

  // Footer
  doc.setFontSize(8);
  doc.setTextColor(100, 100, 100);
  doc.text("Disclaimer: This analysis is for informational purposes only.", margin, pageHeight - 10);

  addPageNumber();

  return doc;
};
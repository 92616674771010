import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Palette, Lock, Unlock, FileText, CheckCircle, Loader2, UserCircle2, Glasses, Grid, Scissors, Diamond, Sparkles, Users, ChevronDown, ChevronUp, AlertCircle, Bot, ClipboardList, Ruler, Star, Leaf } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Badge } from './ui/Badge';
import { Button } from './ui/Button';
import { supabase } from './supabaseClient'; 
import StyleProfileGenerator from './StyleProfileGenerator';
import { Skeleton } from './ui/Skeleton';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';  // Add this import statement

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51PX7ASJEsdwiuGMQ92bSK5vHPZXLHQZ8MhPXFpQ4JKInSR5fpSLd1r3NApDqnQ5t8bYOB4yRbSDmoOpeJJ01TdH600oIIPjBho');

// Define core services offered
const coreServices = [
    { 
        id: 'seasonal-color', 
        name: 'SEASONAL COLOR ', 
        icon: Palette, 
        description: 'Discover your perfect color palette', 
        available: true,
        status: 'NEW',
        feature: 'AI-POWERED',
        price: '$49',
        features: [
            'Customized color palette',
            'Expert AI analysis',
            'Instant results',
        ],
    },
    { 
        id: 'face-shape', 
        name: 'FACE SHAPE', 
        icon: UserCircle2, 
        description: 'Determine your face shape', 
        available: true,
        status: 'NEW',
        feature: 'AI-POWERED',
        price: '$9',
        features: [
            'Face shape determination',
            'Hairstyle recommendations',
            'Feature highlighting advice',
        ],
    },
    { 
        id: 'body-type', 
        name: 'KIBBE BODY TYPE', 
        icon: Ruler, 
        description: 'Discover your Kibbe body type and style recommendations', 
        available: true,
        status: 'NEW',
        feature: 'AI-POWERED',
        price: '$39',
        features: [
            'Kibbe body type determination',
            'Clothing recommendations',
            'Accessories advice',
        ],
    },
    {
        id: 'color-palette-generator',
        name: 'COLOR PALETTE GENERATOR',
        icon: Grid,
        description: 'Create custom color palettes based on your seasonal color',
        available: true,
        status: 'FREE',
        feature: 'COLOR EXPLORATION',
        price: 'FREE',
        features: [
          'Generate palettes of up to 15 colors',
          'Explore seasonal color harmonies',
          'Copy color codes instantly',
        ],
    },
];

// Define enhanced services that require unlocking
const enhancedServices = [
    { 
        id: 'hair-stylist', 
        name: 'HAIR COLOR & CUT STYLIST', 
        icon: Scissors, 
        description: 'Find your perfect hair color and style', 
        unlockedBy: ['seasonal-color', 'face-shape'],
        unlockRequirement: 'Seasonal Color and Face Shape Analyses'
    },
    { 
        id: 'eyewear-stylist', 
        name: 'EYEWEAR STYLIST', 
        icon: Glasses, 
        description: 'Discover frames that complement your face', 
        unlockedBy: 'face-shape',
        unlockRequirement: 'Face Shape Analysis'
    },
    { 
        id: 'makeup', 
        name: 'MAKEUP ARTISTRY GUIDE', 
        icon: Sparkles, 
        description: 'Get personalized makeup suggestions and techniques', 
        unlockedBy: 'seasonal-color',
        unlockRequirement: 'Seasonal Color Analysis'
    },
    { 
        id: 'jewelry', 
        name: 'JEWELRY STYLING ASSISTANT', 
        icon: Diamond, 
        description: 'Find the perfect jewelry to accent your style', 
        unlockedBy: 'body-type',
        unlockRequirement: 'Body Type Analysis'
    },
];

// Define features that are coming soon
const comingSoonFeatures = [
    {
        id: 'style-preferences-questionnaire',
        name: 'STYLE PREFERENCES QUESTIONNAIRE',
        icon: ClipboardList,
        description: 'Take a short quiz to define your style preferences and receive personalized recommendations tailored to your unique taste.',
    },
    {
        id: 'body-measurements',
        name: 'BODY MEASUREMENTS ANALYZER',
        icon: Ruler,
        description: 'Input detailed body measurements for even more precise clothing and style recommendations that flatter your unique shape.',
    },
    {
        id: 'style-icon-inspiration',
        name: 'STYLE ICON INSPIRATION',
        icon: Star,
        description: 'Select style icons or celebrities whose fashion you admire, and get outfit ideas inspired by their looks but tailored to your body type and coloring.',
    },
    {
        id: 'virtual-style-assistant',
        name: 'VIRTUAL STYLE ASSISTANT CHATBOT',
        icon: Bot,
        description: 'Chat with an AI-powered style assistant that can answer your fashion questions, offer outfit advice, and explain style concepts in a conversational manner.',
    },
    {
        id: 'organic-clothing-advisor',
        name: 'ORGANIC CLOTHING ADVISOR',
        icon: Leaf,
        description: 'Discover a curated database of organic clothing companies and receive personalized recommendations for eco-friendly fashion that matches your style.',
    },
];

// ServiceCard component for displaying individual services
const ServiceCard = ({ service, isEnhanced = false, isUnlocked }) => {
    // State variables for loading and Stripe instance
    const [isLoading, setIsLoading] = useState(false);
    const [stripeInstance, setStripeInstance] = useState(null);
    const navigate = useNavigate();

    // Initialize Stripe on component mount
    useEffect(() => {
        const initializeStripe = async () => {
            try {
                const stripe = await stripePromise;
                setStripeInstance(stripe);
                console.log('Stripe initialized successfully');
            } catch (error) {
                console.error('Failed to initialize Stripe:', error);
            }
        };

        initializeStripe();
    }, []);

    // Handle checkout process
    const handleCheckout = async () => {
        console.log(`Checkout initiated for ${service.name}`);
        
        if (!stripeInstance) {
            console.error('Stripe has not been initialized');
            return;
        }
    
        setIsLoading(true);
        try {
            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    service_id: service.id,
                }),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Full error response:', errorText);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
    
            const session = await response.json();
            console.log('Parsed session data:', session);
    
            if (!session.sessionId) {
                throw new Error('No sessionId received from server');
            }
    
            const result = await stripeInstance.redirectToCheckout({
                sessionId: session.sessionId,
            });
    
            if (result.error) {
                console.error('Stripe redirect error:', result.error.message);
                throw new Error(result.error.message);
            }
        } catch (error) {
            console.error('Error in checkout process:', error);
            alert(`Checkout failed: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle action based on service status
    const handleAction = () => {
        if (service.status === 'COMING SOON') {
            console.log(`${service.name} is coming soon!`);
        } else if (isUnlocked || isFreeService) {
            switch(service.id) {
                case 'seasonal-color':
                    navigate('/seasonalColorAnalysis');
                    break;
                case 'face-shape':
                    navigate('/faceShapeAnalysis');
                    break;
                case 'body-type':
                    navigate('/bodyTypeAnalysis');
                    break;
                case 'color-palette-generator':
                    navigate('/colorPaletteGenerator');
                    break;
                default:
                    console.log(`Navigating to ${service.id} service page`);
                    navigate(`/${service.id}`);
            }
        } else {
            handleCheckout();
        }
    };

    // Get button text based on service state
    const getButtonText = () => {
        if (isLoading) return 'PROCESSING...';
        if (service.status === 'COMING SOON') return 'COMING SOON';
        if (service.price === 'FREE') return 'START GENERATOR';
        if (isUnlocked) return 'GO TO SERVICE';
        return 'CHECKOUT';
    };

    // Determine if the service is paid or free
    const isPaidService = ['seasonal-color', 'face-shape', 'body-type'].includes(service.id);
    const isFreeService = service.id === 'color-palette-generator';

    // Social proof data for services
    const socialProofData = {
        'seasonal-color': 1227,
        'face-shape': 985,
        'body-type': 1103,
        "color-palette-generator": 1500
    };
    
    const showSocialProof = isFreeService || (isPaidService && !isUnlocked);

    return (
        <Card className={`h-full transition-all duration-300 shadow-sm hover:shadow-md overflow-hidden flex flex-col ${isFreeService ? 'bg-gradient-to-br from-purple-500 to-pink-500 text-white' : 'bg-white text-black'} rounded-xl relative`}>
            {/* Card Header */}
            <CardHeader className="flex flex-col space-y-2 p-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <service.icon className={`h-5 w-5 ${isFreeService ? 'text-white' : 'text-vibrant'}`} />
                        <CardTitle className={`text-lg font-semibold ${isFreeService ? 'text-white' : 'text-black'}`}>
                            {service.name}
                        </CardTitle>
                    </div>
                    {isFreeService && (
                        <Badge className="bg-white text-purple-500 text-xs font-semibold px-2 py-0.5">
                            FREE
                        </Badge>
                    )}
                    {!isFreeService && (
                        <Badge className={`${isUnlocked ? 'bg-black' : 'bg-vibrant'} text-white text-xs font-semibold px-2 py-0.5`}>
                            {isUnlocked ? 'PURCHASED' : service.status}
                        </Badge>
                    )}
                </div>
                {service.feature && (
                    <Badge variant="outline" className={`self-start ${isFreeService ? 'border-white text-white' : 'border-vibrant text-vibrant'} text-xs font-semibold px-2 py-0.5 mt-1 !transition-none`}>
                        {service.feature}
                    </Badge>
                )}
                {showSocialProof && socialProofData[service.id] && (
                    <div className={`flex items-center space-x-2 text-xs ${isFreeService ? 'text-white' : 'text-gray-600'} mt-1`}>
                        <Users size={14} className={isFreeService ? 'text-white' : 'text-vibrant'} />
                        <span>{socialProofData[service.id].toLocaleString()} uses</span>
                    </div>
                )}
            </CardHeader>
            {/* Card Content */}
            <CardContent className="p-4 pt-0 flex flex-col flex-grow">
                <p className={`text-sm ${isFreeService ? 'text-white' : 'text-gray-600'} mb-3`}>{service.description}</p>
                {service.features && (
                    <ul className="mb-3 text-xs">
                        {service.features.map((feature, index) => (
                            <li key={index} className="flex items-center space-x-1 mb-0.5">
                                <span className={isFreeService ? 'text-white' : 'text-vibrant'}>•</span>
                                <span className={isFreeService ? 'text-white' : ''}>{feature}</span>
                            </li>
                        ))}
                    </ul>
                )}
                <div className="mt-auto space-y-2">
                    {service.price && !isUnlocked && isPaidService && (
                        <div className="flex items-center justify-between">
                            <p className="text-base font-semibold">{service.price}</p>
                            <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full">One-time purchase</span>
                        </div>
                    )}
                    {/* Flashy Price Comparison Badge */}
                    {!isUnlocked && (service.id === 'seasonal-color' || service.id === 'body-type') && (
                        <div className="bg-yellow-300 text-yellow-800 text-xs font-semibold px-2 py-1 rounded-full inline-block mb-2">
                            Compare to in-person visits that charge $150+!
                        </div>
                    )}
                    <Button 
                    className={`w-full rounded-xl ${
                        isFreeService 
                        ? 'bg-white text-purple-500 hover:bg-purple-100' 
                        : isPaidService && isUnlocked
                        ? 'bg-vibrant text-white hover:bg-vibrant/90'
                        : isPaidService && !isUnlocked
                        ? 'bg-black text-white hover:bg-black/90'
                        : 'bg-black text-white hover:bg-vibrant'
                    } transition-colors duration-300`}
                    onClick={handleAction}
                    disabled={isLoading}
                    >
                    {getButtonText()}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

// EnhancedServiceCard component for displaying enhanced services
const EnhancedServiceCard = ({ service, isUnlocked }) => {
    const navigate = useNavigate();
    const [isGenerating, setIsGenerating] = useState(false);
    const [isCheckingReport, setIsCheckingReport] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [reportExists, setReportExists] = useState(false);

    useEffect(() => {
        const checkReport = async () => {
            if (!isUnlocked || !['hair-stylist', 'eyewear-stylist', 'makeup', 'jewelry'].includes(service.id)) return;

            setIsCheckingReport(true);
            try {
                const { data: { user } } = await supabase.auth.getUser();
                if (!user) throw new Error('No active session found');

                const reportType = 
                    service.id === 'hair-stylist' ? 'hair_stylist' :
                    service.id === 'eyewear-stylist' ? 'eyewear_stylist' :
                    service.id === 'makeup' ? 'makeup_artistry' :
                    'jewelry_styling';
                
                const { data, error } = await supabase
                    .from('user_reports')
                    .select('*')
                    .eq('report_type', reportType)
                    .eq('user_id', user.id)
                    .limit(1);

                if (error) throw error;
                setReportExists(data && data.length > 0);
            } catch (error) {
                console.error(`Error checking ${service.id} report:`, error);
                setError('Failed to check if report exists. Please try again.');
            } finally {
                setIsCheckingReport(false);
            }
        };

        checkReport();
    }, [service.id, isUnlocked]);

    const handleGenerateReport = async () => {
        if (!isUnlocked) return;

        setIsGenerating(true);
        setError(null);
        setSuccessMessage(null);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                throw new Error('No active session found. Please log in and try again.');
            }

            const reportType = 
                service.id === 'hair-stylist' ? 'hair-stylist' :
                service.id === 'eyewear-stylist' ? 'eyewear-stylist' :
                service.id === 'makeup' ? 'makeup-artistry' :
                'jewelry-styling';
            
            const response = await fetch(`/api/generate-${reportType}-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
            });

            if (!response.ok) {
                const errorBody = await response.text();
                console.error('Server error response:', errorBody);
                throw new Error(`Failed to generate recommendations. Please try again later.`);
            }

            const data = await response.json();
            if (data.report_id) {
                setReportExists(true);
                setSuccessMessage("Report generated successfully. Click 'View Report' to see the recommendations.");
            } else {
                throw new Error('Failed to save the report. Please try again.');
            }
        } catch (error) {
            console.error('Error generating report:', error);
            setError(error.message);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleViewReport = () => {
        const reportType = 
            service.id === 'hair-stylist' ? 'hair_stylist' :
            service.id === 'eyewear-stylist' ? 'eyewear_stylist' :
            service.id === 'makeup' ? 'makeup_artistry' :
            'jewelry_styling';
        navigate(`/report/${reportType}`);
    }

    const getUnlockColor = (unlockedBy) => {
        return 'bg-blue-500'; // Example return value
    };

    if (isCheckingReport) {
        return (
            <Card className="h-full flex items-center justify-center">
                <Loader2 className="h-8 w-8 animate-spin" />
                <p className="ml-2">Checking report status...</p>
            </Card>
        );
    }

    return (
        <Card className="h-full transition-all duration-300 shadow-sm hover:shadow-md overflow-hidden flex flex-col bg-white rounded-xl relative">
            <div className={`absolute top-0 left-0 w-full h-1 ${getUnlockColor(service.unlockedBy)}`} />
            <CardHeader className="flex flex-col space-y-2 p-4">
                <div className="flex items-start">
                    <service.icon className="h-5 w-5 text-gray-600 flex-shrink-0 mt-1 mr-2" />
                    <div className="flex flex-col">
                        <CardTitle className="text-lg font-semibold text-gray-800">
                            {service.name}
                        </CardTitle>
                        <Badge 
                            variant="outline" 
                            className={`${
                                isUnlocked ? 'border-green-500 text-green-600' : 'border-gray-300 text-gray-600'
                            } text-xs font-semibold px-2 py-0.5 mt-1 self-start`}
                        >
                            {isUnlocked ? <Unlock className="w-3 h-3 mr-1 inline" /> : <Lock className="w-3 h-3 mr-1 inline" />}
                            {isUnlocked ? 'UNLOCKED' : 'LOCKED'}
                        </Badge>
                    </div>
                </div>
            </CardHeader>
            <CardContent className="p-4 pt-0 flex flex-col flex-grow">
                <p className="text-sm text-gray-600 mb-4">{service.description}</p>
                {error && (
                    <Alert variant="destructive" className="mb-4">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                {successMessage && (
                    <Alert variant="success" className="mb-4">
                        <CheckCircle className="h-4 w-4" />
                        <AlertTitle>Success</AlertTitle>
                        <AlertDescription>{successMessage}</AlertDescription>
                    </Alert>
                )}
                <div className="mt-auto space-y-2">
                    {isUnlocked && (
                        <>
                            {reportExists ? (
                                <Button 
                                    onClick={handleViewReport}
                                    className="w-full bg-white border border-black text-black hover:bg-gray-100"
                                >
                                    <FileText className="mr-2 h-4 w-4" />
                                    View Report
                                </Button>
                            ) : (
                                <Button 
                                    onClick={handleGenerateReport}
                                    disabled={isGenerating}
                                    className="w-full mb-2"
                                >
                                    {isGenerating ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            Generating...
                                        </>
                                    ) : (
                                        <>Generate Report</>
                                    )}
                                </Button>
                            )}
                        </>
                    )}
                    {!isUnlocked && (
                        <p className="text-sm text-gray-500 text-center">
                            Complete the required analyses to unlock this service.
                        </p>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};


// ComingSoonCard component for displaying upcoming features
const ComingSoonCard = ({ feature }) => {
    return (
        <Card className="h-full transition-all duration-300 shadow-sm hover:shadow-md overflow-hidden flex flex-col bg-gradient-to-br from-gray-100 to-gray-200 rounded-xl relative group">
            {/* Card Header */}
            <CardHeader className="flex flex-col items-center justify-center p-4">
                <feature.icon className="h-12 w-12 text-gray-400 mb-2" />
                <CardTitle className="text-xl font-semibold text-gray-700 text-center">{feature.name}</CardTitle>
                <Badge className="bg-yellow-400 text-yellow-800 text-xs font-semibold px-2 py-0.5 mt-2">
                    COMING SOON
                </Badge>
            </CardHeader>
            {/* Card Content */}
            <CardContent className="p-4 pt-0 flex flex-col items-center text-center">
                <p className="text-sm text-gray-600">{feature.description}</p>
            </CardContent>
        </Card>
    );
};

// Main component for the AI Services Dashboard
const AIServicesDashboard = () => {
    // State variables for loading and user data
    const [isLoading, setIsLoading] = useState(true);
    const [userFullName, setUserFullName] = useState('');
    const [hairStyleReportExists, setHairStyleReportExists] = useState(false);
    const [unlockedServices, setUnlockedServices] = useState([]);
    const [styleProfile, setStyleProfile] = useState({
        colorAnalysis: null,
        faceShape: null,
        bodyType: null
    });
    const [expandedSections, setExpandedSections] = useState({
        styleProfile: true,
        outfitGenerator: true,
        coreServices: true,
        enhancedServices: true,
        comingSoon: true
    });

    // Fetch all necessary data when component mounts
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchUserProfile(),
                fetchUnlockedServices(),
                fetchStyleProfile(),
                checkHairStyleReport()
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    // Fetch user's full name
    const fetchUserProfile = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const { data, error } = await supabase
                .from('user_profiles')
                .select('full_name')
                .eq('id', user.id)
                .single();

            if (error) {
                console.error('Error fetching user profile:', error);
            } else if (data) {
                setUserFullName(data.full_name || '');
            }
        }
    };

    // Fetch services unlocked by the user
    const fetchUnlockedServices = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const unlockedServices = [];

            // Check for color analysis
            const { data: colorData } = await supabase
                .from('user_color_analyses')
                .select('*')
                .eq('user_id', user.id)
                .limit(1);

            if (colorData?.length > 0) unlockedServices.push('seasonal-color');

            // Check for face shape analysis
            const { data: faceData } = await supabase
                .from('user_face_shape_analyses')
                .select('*')
                .eq('user_id', user.id)
                .limit(1);

            if (faceData?.length > 0) unlockedServices.push('face-shape');

            // Check for body type analysis (if applicable)
            const { data: bodyData } = await supabase
                .from('user_body_type_analyses')
                .select('*')
                .eq('user_id', user.id)
                .limit(1);

            if (bodyData?.length > 0) unlockedServices.push('body-type');

            // Update state with unlocked services
            setUnlockedServices(unlockedServices);
        }
    };

    // Fetch user's style profile data
    const fetchStyleProfile = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const fetchData = async (table) => {
                const { data, error } = await supabase
                    .from(table)
                    .select('*')
                    .eq('user_id', user.id)
                    .order('created_at', { ascending: false })
                    .limit(1);

                if (error) console.error(`Error fetching ${table}:`, error);
                return data && data.length > 0 ? data[0] : null;
            };

            const colorData = await fetchData('user_color_analyses');
            const faceData = await fetchData('user_face_shape_analyses');
            const bodyData = await fetchData('user_body_type_analyses');

            const newStyleProfile = {
                colorAnalysis: colorData,
                faceShape: faceData,
                bodyType: bodyData
            };

            setStyleProfile(newStyleProfile);
        }
    };


    //Function to check if hair style report exists
    const checkHairStyleReport = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const { data, error } = await supabase
                .from('user_reports')
                .select('*')
                .eq('report_type', 'hair_stylist')
                .eq('user_id', user.id)
                .limit(1);

            if (error) {
                console.error('Error checking hair style report:', error);
            } else {
                setHairStyleReportExists(data && data.length > 0);
            }
        }
    };

        // Check if a service is unlocked
        const isServiceUnlocked = (service) => {
            if (Array.isArray(service.unlockedBy)) {
                return service.unlockedBy.every(requirement => unlockedServices.includes(requirement));
            }
            return unlockedServices.includes(service.unlockedBy);
        };

    // Update core services with unlock status
    const updatedCoreServices = coreServices.map(service => ({
        ...service,
        available: isServiceUnlocked(service)
    }));

    // Update enhanced services with unlock status
    const updatedEnhancedServices = enhancedServices.map(service => ({
        ...service,
        available: isServiceUnlocked(service)
    }));

    // Toggle expansion of dashboard sections
    const toggleSection = (section) => {
        setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
    };

    // Section header component with toggle functionality
    const SectionHeader = ({ title, section }) => (
        <div 
          className="flex justify-between items-center cursor-pointer py-3 px-6 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
          onClick={() => toggleSection(section)}
        >
          <h2 className="text-xl font-extrabold text-gray-800">{title}</h2>
          {expandedSections[section] ? <ChevronUp /> : <ChevronDown />}
        </div>
      );

    // Handle updates to the style profile
    const handleProfileUpdate = (updatedProfile) => {
        setStyleProfile(updatedProfile);
    };

    // Show loading skeleton while data is being fetched
    if (isLoading) {
        return (
            <div className="space-y-8 p-6 bg-gray-50">
                <Skeleton className="h-16 w-full" />
                <Skeleton className="h-64 w-full" />
                <Skeleton className="h-96 w-full" />
                <Skeleton className="h-64 w-full" />
                <Skeleton className="h-64 w-full" />
            </div>
        );
    }

    // Render the dashboard
    return (
        <div className="space-y-8 p-6 bg-gray-50">
            {/* Welcome message */}
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-6">
                <div className="flex">
                    <AlertCircle className="h-5 w-5 text-blue-400" />
                    <p className="ml-3 text-sm text-blue-700">
                        {userFullName 
                            ? `Welcome, ${userFullName}! Let's explore our personalized styling services to build your complete style profile.`
                            : "Welcome to our personalized styling services! Start with our core services to build your complete style profile."}
                    </p>
                </div>
            </div>

            {/* Style Profile Section */}
            <Card>
                <SectionHeader title={userFullName ? <span className="text-blue-600 capitalize">{userFullName.toUpperCase()}'S STYLE PROFILE</span> : "Your Style Profile"} section="styleProfile" />
                {expandedSections.styleProfile && (
                    <CardContent className="pt-6 pb-8 px-6">
                        <StyleProfileGenerator 
                            styleProfile={styleProfile} 
                            onProfileUpdate={handleProfileUpdate}
                            userFullName={userFullName}
                        />
                    </CardContent>
                )}
            </Card>

            {/* Core Services Section */}
            <Card>
                <SectionHeader title="STYLE ANALYSIS SERVICES" section="coreServices" />
                {expandedSections.coreServices && (
                    <CardContent className="pt-6 pb-8 px-6">
                        <h3 className="text-lg font-semibold mb-4">Core Style Analysis Services</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {updatedCoreServices.map((service) => (
                                <ServiceCard 
                                    key={service.id} 
                                    service={service} 
                                    isUnlocked={unlockedServices.includes(service.id)}
                                />
                            ))}
                        </div>
                        {/* Add Price Comparison Note */}
                        <p className="text-sm text-gray-500 mt-4">
                            *In-person Seasonal Color Analysis typically ranges from $150 to $250.
                        </p>
                    </CardContent>
                )}
            </Card>

            {/* Enhanced Services Section */}
            <Card>
                <SectionHeader title="ADVANCED STYLING TOOLS" section="enhancedServices" />
                {expandedSections.enhancedServices && (
                    <CardContent className="pt-6 pb-8 px-6">
                        <p className="text-sm text-gray-600 mb-4">Unlock advanced styling tools by completing our core analyses. Each enhanced service is tailored to your unique profile.</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {updatedEnhancedServices.map((service) => (
                                <EnhancedServiceCard 
                                    key={service.id} 
                                    service={service} 
                                    isUnlocked={isServiceUnlocked(service)}
                                    hairStyleReportExists={hairStyleReportExists}
                                    setHairStyleReportExists={setHairStyleReportExists}
                                />
                            ))}
                        </div>
                    </CardContent>
                )}
            </Card>

            {/* Coming Soon Section */}
            <Card>
                <SectionHeader title="COMING SOON" section="comingSoon" />
                {expandedSections.comingSoon && (
                    <CardContent className="pt-6 pb-8 px-6">
                        <p className="text-sm text-gray-600 mb-4">Explore our upcoming features designed to enhance your style journey:</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                            {comingSoonFeatures.map((feature) => (
                                <ComingSoonCard key={feature.id} feature={feature} />
                            ))}
                        </div>
                    </CardContent>
                )}
            </Card>
        </div>
    );
};


export default AIServicesDashboard;
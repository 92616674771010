import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Button } from './ui/Button';
import { AlertCircle, CheckCircle, Download, Trash, User, Mail, Key } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';
import { Separator } from "./ui/Separator";

const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchUserProfile();
    fetchUserReports();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
        setFormData(prevState => ({
          ...prevState,
          fullName: user.user_metadata.full_name || '',
          email: user.email || '',
        }));
      }
    } catch (error) {
      setError('Failed to fetch user profile');
    } finally {
      setLoading(false);
    }
  };
  

  const fetchUserReports = async () => {
    try {
      const { data, error } = await supabase
        .from('user_reports')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setReports(data);
    } catch (error) {
      console.error('Error fetching user reports:', error);
      setError('Failed to fetch user reports');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Update user metadata (full name)
      const { error: updateError } = await supabase.auth.updateUser({
        data: { full_name: formData.fullName },
      });

      if (updateError) throw updateError;

      // Update email if changed
      if (formData.email !== user.email) {
        const { error: emailError } = await supabase.auth.updateUser({
          email: formData.email,
        });

        if (emailError) throw emailError;
      }

      // Update password if provided
      if (formData.newPassword) {
        if (formData.newPassword !== formData.confirmPassword) {
          throw new Error('New password and confirmation do not match');
        }

        const { error: passwordError } = await supabase.auth.updateUser({
          password: formData.newPassword,
        });

        if (passwordError) throw passwordError;
      }

      setSuccess('Profile updated successfully');
      fetchUserProfile(); // Refresh user data
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = async (report) => {
    console.log('Attempting to download file:', report.file_name);
    try {
      const { data, error } = await supabase.storage
        .from('user_reports')
        .download(report.file_name);

      if (error) {
        if (error.message === 'Object not found') {
          // File doesn't exist in storage
          const shouldRemove = window.confirm('This file no longer exists in storage. Would you like to remove it from the list?');
          if (shouldRemove) {
            await removeReportEntry(report.id);
          }
        } else {
          throw error;
        }
      } else {
        // File exists, proceed with download
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = report.file_name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading report:', error);
      setError('Failed to download report');
    }
  };

  const removeReportEntry = async (reportId) => {
    try {
      console.log('Attempting to delete report with ID:', reportId);
  
      const { data, error } = await supabase
        .from('user_reports')
        .delete()
        .match({ id: reportId })
        .select(); // Add this to return the deleted row
  
      if (error) {
        console.error('Error deleting from database:', error);
        throw error;
      }
  
      console.log('Delete operation result:', data);
  
      if (!data || data.length === 0) {
        console.warn('No data returned from delete operation. The report may have already been deleted.');
        // Proceed with removing from local state anyway
        setReports(prevReports => prevReports.filter(report => report.id !== reportId));
        setSuccess('Report entry removed from view');
        return;
      }
  
      // If we reach this point, the delete was successful
      setReports(prevReports => prevReports.filter(report => report.id !== reportId));
      setSuccess('Report entry removed successfully');
  
      // Optionally, also delete the file from storage
      const deletedReport = data[0];
      if (deletedReport && deletedReport.file_name) {
        const { error: storageError } = await supabase.storage
          .from('user_reports')
          .remove([deletedReport.file_name]);
  
        if (storageError) {
          console.error('Error deleting file from storage:', storageError);
          // Note: We don't throw this error as the database entry was successfully deleted
        }
      } else {
        console.warn('No file_name found in deleted report data. Skipping storage deletion.');
      }
  
    } catch (error) {
      console.error('Error removing report entry:', error);
      setError('Failed to remove report entry: ' + error.message);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex min-h-screen bg-[#f5f5f5] items-center justify-center p-4">
      <Card className="w-full max-w-2xl rounded-lg shadow-md bg-white">
        <CardHeader className="pb-2">
          <CardTitle className="text-3xl font-bold text-center text-gray-800">User Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="fullName" className="text-sm font-medium text-gray-700">Full Name</label>
              <div className="relative">
                <User className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
              <div className="relative">
                <Mail className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="newPassword" className="text-sm font-medium text-gray-700">New Password</label>
              <div className="relative">
                <Key className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700">Confirm New Password</label>
              <div className="relative">
                <Key className="absolute top-3 left-3 h-5 w-5 text-gray-400" />
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            {error && (
              <Alert variant="destructive" className="rounded-xl">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {success && (
              <Alert variant="success" className="bg-green-50 text-green-600 rounded-xl">
                <CheckCircle className="h-4 w-4" />
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}

            <Button 
              type="submit" 
              className="w-full bg-black text-white hover:bg-vibrant transition-colors rounded-full py-3 font-semibold"
              disabled={loading}
            >
              {loading ? 'Updating Profile...' : 'Update Profile'}
            </Button>
          </form>

          <div className="space-y-6 mt-8">
  <Separator className="my-8" />
  
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Your Saved Reports</h3>
    
    {reports.length === 0 ? (
      <p className="text-gray-600 italic">You don't have any saved reports yet.</p>
    ) : (
      <div className="space-y-4">
        {reports.map((report) => (
          <div key={report.id} className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-gray-50 p-4 rounded-lg border border-gray-200 hover:bg-gray-100 transition-colors duration-200">
            <div className="mb-2 sm:mb-0">
              <h4 className="text-lg font-medium text-gray-800">{report.report_type}</h4>
              <p className="text-sm text-gray-600">{new Date(report.created_at).toLocaleDateString()} - {new Date(report.created_at).toLocaleTimeString()}</p>
            </div>
            <div className="flex space-x-2 mt-2 sm:mt-0">
              <Button
                onClick={() => downloadReport(report)}
                variant="outline"
                size="sm"
                className="flex items-center text-blue-600 hover:text-blue-800 hover:bg-blue-50"
              >
                <Download className="h-4 w-4 mr-2" />
                Download
              </Button>
              <Button
                onClick={() => removeReportEntry(report.id)}
                variant="outline"
                size="sm"
                className="flex items-center text-red-600 hover:text-red-800 hover:bg-red-50"
              >
                <Trash className="h-4 w-4 mr-2" />
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserProfilePage;
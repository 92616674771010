import React from 'react';
import { cn } from "../../lib/utils";

const Progress = React.forwardRef(({ className, value, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("relative h-2 w-full overflow-hidden rounded-full bg-gray-200", className)}
      {...props}
    >
      <div
        className="h-full transition-all duration-300 ease-in-out"
        style={{
          width: `${value || 0}%`,
          background: `linear-gradient(90deg, 
            rgba(59,130,246,1) ${value}%, 
            rgba(147,197,253,1) ${value + 5}%, 
            rgba(59,130,246,1) ${value + 10}%)`,
          animation: 'pulse 1.5s ease-in-out infinite',
        }}
      />
      <style jsx>{`
        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }
      `}</style>
    </div>
  );
});

Progress.displayName = "Progress";

export { Progress };
// src/utils/imageValidation.js

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export const validateImage = (file) => {
  // Check file type
  const validTypes = ['image/jpeg', 'image/png'];
  if (!validTypes.includes(file.type)) {
    return {
      isValid: false,
      error: 'Invalid file type. Please upload a JPG or PNG image.'
    };
  }

  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    return {
      isValid: false,
      error: 'File is too large. Please upload an image smaller than 10MB.'
    };
  }

  return { isValid: true };
};
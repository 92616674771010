import React from 'react';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import { Camera } from 'lucide-react';

const AnalyzeButton = ({ isLoading, loadingProgress, onClick, disabled }) => {
  console.log('AnalyzeButton render:', { isLoading, loadingProgress, disabled });

  return (
    <div className="w-full mb-10">
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={isLoading ? "default" : "secondary"}
        className={`w-full rounded-full py-3 ${
          isLoading ? 'bg-vibrant hover:bg-vibrant-600' : 'bg-gray-800 hover:bg-gray-700'
        } text-white transition-colors duration-200`}
      >
        <div className="flex items-center justify-center">
          {isLoading ? (
            <>
              <Camera className="w-5 h-5 mr-2 animate-spin" />
              <span>Analyzing...</span>
            </>
          ) : (
            <>
              <Camera className="w-5 h-5 mr-2" />
              <span>Analyze Image</span>
            </>
          )}
        </div>
      </Button>
      {isLoading && (
        <div className="mt-2">
          <Progress 
            value={loadingProgress} 
            className="w-full"
            indicatorClassName="bg-yellow-400" // This adds the golden yellow color to the progress bar
          />
          <p className="text-center text-sm mt-1">{loadingProgress.toFixed(0)}%</p>
        </div>
      )}
    </div>
  );
};

export default AnalyzeButton;
// SavedOutfits.js
import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/Accordion';
import { Loader2 } from 'lucide-react';

const SavedOutfits = () => {
  const [savedOutfits, setSavedOutfits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSavedOutfits();
  }, []);

  const fetchSavedOutfits = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('saved_outfits')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSavedOutfits(data);
    } catch (error) {
      console.error('Error fetching saved outfits:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader2 className="h-8 w-8 animate-spin" />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Saved Outfits</CardTitle>
      </CardHeader>
      <CardContent>
        {savedOutfits.length === 0 ? (
          <p>You haven't saved any outfits yet.</p>
        ) : (
          <Accordion type="single" collapsible>
            {savedOutfits.map((outfit, index) => (
              <AccordionItem key={outfit.id} value={`item-${index}`}>
                <AccordionTrigger>
                  Outfit {index + 1} - {new Date(outfit.created_at).toLocaleDateString()}
                </AccordionTrigger>
                <AccordionContent>
                  {Object.entries(outfit.outfit_data).map(([key, value]) => (
                    <div key={key} className="mb-2">
                      <strong className="capitalize">{key}:</strong> {value.description}
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </CardContent>
    </Card>
  );
};

export default SavedOutfits;
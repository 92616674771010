
// Import the createClient function from Supabase
import { createClient } from '@supabase/supabase-js'

// Retrieve the Supabase URL and anonymous key from environment variables
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// Create and export the Supabase client
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    // Keep the user logged in between page refreshes
    persistSession: true,

    // Automatically refresh the token before it expires
    autoRefreshToken: true,

    // Look for auth tokens in the URL (useful for email confirmations)
    detectSessionInUrl: true,

    // Use PKCE (Proof Key for Code Exchange) flow for added security
    // This is recommended for public clients (like web apps)
  },
  // You can add global settings here, for example:
  // global: {
  //   headers: { 'x-my-custom-header': 'my-app-name' },
  // },
})
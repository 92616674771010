import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useParams } from 'react-router-dom';
import { supabase } from './components/supabaseClient';
import ImageAnalysisApp from './components/ImageAnalysisApp';
import AIServicesDashboard from './components/AIServicesDashboard';
import SignUpPage from './components/SignUpPage';
import SignInPage from './components/SignInPage';
import UserProfilePage from './components/UserProfilePage';
import FaceShapeAnalysis from './components/FaceShapeAnalysis';
import BodyTypeAnalysis from './components/BodyTypeAnalysis';
import SeasonalColorPaletteGenerator from './components/SeasonalColorPaletteGenerator';
import VerifyEmailPage from './components/VerifyEmailPage';
import { Button } from './components/ui/Button';
import { Separator } from './components/ui/Separator';
import { Home, UserCircle2, Grid, Palette, Shirt, Sparkles, Shapes, Glasses, Diamond, Scissors, ChevronRight, Menu, LogOut, HelpCircle, Lock, Ruler } from 'lucide-react';
import SuccessPage from './components/SuccessPage';
import CancelPage from './components/CancelPage';
import ReportComponent from './components/ReportComponent';
import OutfitGeneratorPage from './components/OutfitGeneratorPage';


function App() {
  const [session, setSession] = useState(null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [unlockedServices, setUnlockedServices] = useState([]);
  const [availableReports, setAvailableReports] = useState([]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUnlockedServices = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('user_services')
        .select('service_id')
        .eq('user_id', user.id);
  
      if (error) {
        console.error('Error fetching unlocked services:', error);
      } else {
        const unlocked = data.map(item => item.service_id);
        console.log('Unlocked services:', unlocked);
        setUnlockedServices(unlocked);
      }
    }
  };

  const fetchAvailableReports = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('user_reports')
        .select('report_type')
        .eq('user_id', user.id);
      
      if (data && !error) {
        setAvailableReports(data.map(report => report.report_type));
      }
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await fetchUnlockedServices();
      await fetchAvailableReports();
    };
  
    fetchData();
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setSession(null);
  };

  const isServiceUnlocked = (serviceId) => {
    const isUnlocked = unlockedServices.includes(serviceId) || 
           serviceId === 'dashboard' || 
           serviceId === 'profile' ||
           serviceId === 'outfit-generator' ||
           serviceId === 'colorPaletteGenerator' ||
           (serviceId === 'seasonalColorAnalysis' && unlockedServices.includes('seasonal-color')) ||
           (serviceId === 'faceShapeAnalysis' && unlockedServices.includes('face-shape')) ||
           (serviceId === 'bodyTypeAnalysis' && unlockedServices.includes('body-type'));
  
    const reportType = {
      'hair-stylist': 'hair_stylist',
      'eyewear-stylist': 'eyewear_stylist',
      'makeup': 'makeup_artistry',
      'jewelry': 'jewelry_styling'
    }[serviceId];
  
    return isUnlocked || (reportType && availableReports.includes(reportType));
  };

  const UnlockedServiceRoute = ({ children, serviceId }) => {
    if (isServiceUnlocked(serviceId)) {
      return children;
    } else {
      return <Navigate to="/dashboard" replace />;
    }
  };

  const sidebarItems = [
    { icon: Home, label: 'DASHBOARD', view: 'dashboard', type: 'main' },
    { icon: UserCircle2, label: 'PROFILE', view: 'profile', type: 'main' },
    { type: 'separator', label: 'Core Services' },
    { icon: Palette, label: 'COLOR ANALYSIS', view: 'seasonalColorAnalysis', type: 'core', ai: true },
    { icon: Shapes, label: 'FACE SHAPE', view: 'faceShapeAnalysis', type: 'core', ai: true },
    { icon: Ruler, label: 'BODY TYPE', view: 'bodyTypeAnalysis', type: 'core', ai: true },
    { type: 'separator', label: 'AI Tools' },
    { icon: Shirt, label: 'OUTFIT GENERATOR', view: 'outfit-generator', type: 'ai-tool', ai: true },
    { icon: Grid, label: 'PALETTE GENERATOR', view: 'colorPaletteGenerator', type: 'ai-tool', badge: 'FREE' },
    { type: 'separator', label: 'Enhanced Services' },
    { icon: Scissors, label: 'HAIR STYLIST', view: 'hair-stylist', type: 'enhanced' },
    { icon: Glasses, label: 'EYEWEAR STYLIST', view: 'eyewear-stylist', type: 'enhanced' },
    { icon: Sparkles, label: 'MAKEUP GUIDE', view: 'makeup', type: 'enhanced' },
    { icon: Diamond, label: 'JEWELRY STYLIST', view: 'jewelry', type: 'enhanced' },
  ];
  
  const SidebarContent = ({ showLabels = true }) => {
    const navigate = useNavigate();
    return (
      <nav className="flex-1 overflow-y-auto py-1">
        {sidebarItems.map((item, index) => {
          if (item.type === 'separator') {
            return (
              <React.Fragment key={index}>
                <Separator className="my-1 bg-gray-700" />
                {showLabels && (
                  <p className="text-[10px] text-gray-500 px-3 py-0.5 uppercase">{item.label}</p>
                )}
              </React.Fragment>
            );
          }
  
          const isUnlocked = isServiceUnlocked(item.view);
          const isEnhancedService = item.type === 'enhanced';
          const reportType = {
            'hair-stylist': 'hair_stylist',
            'eyewear-stylist': 'eyewear_stylist',
            'makeup': 'makeup_artistry',
            'jewelry': 'jewelry_styling'
          }[item.view];
          const hasReport = reportType && availableReports.includes(reportType);
  
          return (
            <div key={index} className="relative px-2 py-0.5">
              <Button
                onClick={() => {
                  if (isUnlocked || item.type === 'main' || item.type === 'ai-tool' || (isEnhancedService && hasReport)) {
                    if (isEnhancedService && hasReport) {
                      navigate(`/report/${reportType}`);
                    } else {
                      setCurrentView(item.view);
                      navigate(`/${item.view}`);
                    }
                    if (isMobile) setIsSidebarOpen(false);
                  }
                }}
                variant="ghost"
                className={`w-full justify-start px-3 py-1 text-xs ${
                  currentView === item.view ? 'text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                } ${
                  isUnlocked || item.type === 'main' || item.type === 'ai-tool' || (isEnhancedService && hasReport)
                    ? 'font-bold text-white cursor-pointer' 
                    : 'opacity-50 cursor-not-allowed'
                }`}
                style={{ position: 'relative', zIndex: 1 }} 
              >
                <item.icon size={16} className={`${showLabels ? "mr-2" : "mx-auto"} w-4 h-4 flex-shrink-0`} />
                {showLabels && (
                  <div className="flex items-center justify-between w-full">
                    <span>{item.label}</span>
                    <div className="flex items-center space-x-1">
                      {isUnlocked && item.ai && (
                        <span className="px-1 py-0.5 text-[10px] font-semibold bg-vibrant text-white rounded">
                          AI
                        </span>
                      )}
                      {item.badge && (
                        <span className="px-1 py-0.5 text-[10px] font-semibold bg-green-500 text-white rounded">
                          {item.badge}
                        </span>
                      )}
                      {!isUnlocked && !hasReport && item.type === 'enhanced' && (
                        <Lock className="h-3 w-3 text-gray-400" />
                      )}
                      {hasReport && (
                        <span className="px-1 py-0.5 text-[10px] font-semibold bg-blue-500 text-white rounded">
                          REPORT
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </Button>
              {currentView === item.view && (
                <div 
                  className="absolute inset-1 bg-white bg-opacity-10 rounded-sm -z-10"
                />
              )}
            </div>
          );
        })}
      </nav>
    );
  };

  const MainLayout = ({ children }) => {
    return (
      <>
        {isMobile && (
          <header className="bg-black text-white p-4 flex justify-between items-center">
            <h1 className="text-lg font-normal">PERSONA INDEX</h1>
            <Button
              onClick={toggleSidebar}
              variant="ghost"
              className="text-white"
              aria-label="Toggle menu"
            >
              <Menu size={24} />
            </Button>
          </header>
        )}
        
        {/* Desktop Sidebar */}
        {!isMobile && (
          <aside 
            className={`absolute top-2 left-0 h-[calc(100%-1rem)] bg-black text-white transition-all duration-300 ease-in-out z-10 ${
              isSidebarOpen ? 'w-72' : 'w-16'
            } flex flex-col rounded-r-2xl overflow-hidden`}
          >
            <div className="p-4 flex items-center justify-between">
  {isSidebarOpen && (
    <h1 className="text-lg font-extrabold whitespace-nowrap overflow-hidden text-ellipsis">
      PERSONA INDEX
    </h1>
  )}
  <Button
  onClick={toggleSidebar}
  variant="ghost"
  className={`text-gray-400 hover:text-white hover:bg-gray-700 rounded-full p-1 transition-all ${isSidebarOpen ? '' : 'mx-auto'}`}
  aria-label={isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
>
  <ChevronRight 
    size={20} 
    className={`transform transition-transform ${isSidebarOpen ? 'rotate-180' : ''}`}
  />
</Button>
</div>
            <Separator className="bg-gray-700" />
            <SidebarContent showLabels={isSidebarOpen} />
            <div className="mt-auto">
              <Separator className="bg-gray-700" />
              {session && (
                <div className="p-2">
                  <div className={`flex items-center text-xs font-normal text-gray-300 py-2 px-2 rounded ${!isSidebarOpen && 'justify-center'}`}>
                    <UserCircle2 size={18} />
                    {isSidebarOpen && <span className="ml-3 truncate">{`Signed in as ${session.user.email}`}</span>}
                  </div>
                  <Button
                    onClick={handleSignOut}
                    variant="ghost"
                    className={`w-full text-xs font-normal text-gray-300 hover:bg-gray-800 hover:text-white py-2 px-2 rounded ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}
                  >
                    <LogOut size={18} />
                    {isSidebarOpen && <span className="ml-3">SIGN OUT</span>}
                  </Button>
                </div>
              )}
              <Separator className="bg-gray-700" />
              <div className="p-2">
                <a
                  href="mailto:dryan@generalist-labs.com"
                  className={`flex items-center text-xs font-normal text-gray-300 hover:bg-gray-800 hover:text-white uppercase py-2 px-2 rounded ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}
                >
                  <HelpCircle size={18} />
                  {isSidebarOpen && <span className="ml-3">HELP & SUPPORT</span>}
                </a>
              </div>
            </div>
          </aside>
        )}
        
        {/* Mobile Sidebar */}
        {isMobile && (
          <aside 
            className={`fixed top-0 left-0 h-full w-72 z-50 bg-black text-white transition-all duration-300 ease-in-out flex flex-col overflow-hidden transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <div className="p-4 flex items-center justify-between">
              <h1 className="text-lg font-normal whitespace-nowrap overflow-hidden text-ellipsis">
                PERSONA INDEX
              </h1>
              <Button
                onClick={toggleSidebar}
                variant="ghost"
                className="text-gray-300 hover:text-white"
                aria-label="Close sidebar"
              >
                <ChevronRight size={20} />
              </Button>
            </div>
            <Separator className="bg-gray-700" />
            <SidebarContent />
            <Separator className="bg-gray-700" />
            {session && (
              <div className="p-4">
                <div className="flex items-center justify-start text-xs font-normal text-gray-300 py-2 px-4 rounded">
                  <UserCircle2 size={18} className="mr-3" />
                  {`Signed in as ${session.user.email}`}
                </div>
                <Button
                  onClick={handleSignOut}
                  variant="ghost"
                  className="w-full justify-start text-xs font-normal text-gray-300 hover:bg-gray-800 hover:text-white py-2 px-4 rounded"
                >
                  <LogOut size={18} className="mr-3" />
                  SIGN OUT
                </Button>
              </div>
            )}
            <div className="p-4">
              <a
                href="mailto:dryan@generalist-labs.com"
                className="flex items-center justify-start text-xs font-normal text-gray-300 hover:bg-gray-800 hover:text-white uppercase py-2 px-4 rounded"
              >
                <HelpCircle size={18} className="mr-3" />
                HELP & SUPPORT
              </a>
            </div>
          </aside>
        )}
        
        <main className={`h-full overflow-auto transition-all duration-300 ${
          isMobile ? 'pt-16' : (isSidebarOpen ? 'ml-64' : 'ml-16')
        }`}>
          <div className="p-4 md:p-8">
            {children}
          </div>
        </main>

        {isMobile && isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={toggleSidebar}
          />
        )}
      </>
    );
  };

  const ProtectedRoute = ({ children }) => {
    return session ? <MainLayout>{children}</MainLayout> : <Navigate to="/signin" replace />;
  };

  return (
    <Router>
      <div className="relative h-screen bg-[#f5f5f5] overflow-hidden font-sans">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/signin" element={!session ? <SignInPage /> : <Navigate to="/dashboard" replace />} />
            <Route path="/signup" element={!session ? <SignUpPage /> : <Navigate to="/dashboard" replace />} />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/dashboard" element={<ProtectedRoute><AIServicesDashboard /></ProtectedRoute>} />
            <Route path="/seasonalColorAnalysis" element={
              <ProtectedRoute>
                <UnlockedServiceRoute serviceId="seasonal-color">
                  <ImageAnalysisApp />
                </UnlockedServiceRoute>
              </ProtectedRoute>
            } />
            <Route path="/faceShapeAnalysis" element={
              <ProtectedRoute>
                <UnlockedServiceRoute serviceId="face-shape">
                  <FaceShapeAnalysis />
                </UnlockedServiceRoute>
              </ProtectedRoute>
            } />
            <Route path="/bodyTypeAnalysis" element={
              <ProtectedRoute>
                <UnlockedServiceRoute serviceId="body-type">
                  <BodyTypeAnalysis />
                </UnlockedServiceRoute>
              </ProtectedRoute>
            } />
            <Route path="/colorPaletteGenerator" element={
              <ProtectedRoute>
                <SeasonalColorPaletteGenerator />
              </ProtectedRoute>
            } />
            <Route path="/outfit-generator" element={<ProtectedRoute><OutfitGeneratorPage /></ProtectedRoute>} />
            <Route 
          path="/report/:reportType" 
          element={
            <ProtectedRoute>
              <ReportComponent />
            </ProtectedRoute>
          } 
        />
            <Route path="/profile" element={<ProtectedRoute><UserProfilePage /></ProtectedRoute>} />
            <Route path="/success" element={<ProtectedRoute><SuccessPage /></ProtectedRoute>} />
            <Route path="/cancel" element={<ProtectedRoute><CancelPage /></ProtectedRoute>} />
            <Route path="/:view" element={<ProtectedRoute><ComingSoon /></ProtectedRoute>} />
            <Route path="/" element={<Navigate to={session ? "/dashboard" : "/signin"} replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

const AuthCallback = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('Initializing...');

  useEffect(() => {
    let timer;

    const checkSession = async () => {
      setStatus('Checking session...');
      const { data: { session }, error } = await supabase.auth.getSession();
      console.log('Session check result:', session ? 'Session found' : 'No session', error);
      
      if (session) {
        setStatus('Session found, redirecting to dashboard...');
        navigate('/dashboard');
      } else {
        setStatus('No session found, waiting for auth state change...');
        // If no session after 10 seconds, redirect to sign in
        timer = setTimeout(() => {
          setStatus('Timed out, redirecting to sign in...');
          navigate('/signin');
        }, 10000);
      }
    };

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session ? 'Session exists' : 'No session');
      if (event === 'SIGNED_IN' && session) {
        clearTimeout(timer);
        setStatus('Signed in, redirecting to dashboard...');
        navigate('/dashboard');
      }
    });

    checkSession();

    return () => {
      console.log('Cleaning up AuthCallback');
      authListener.subscription.unsubscribe();
      clearTimeout(timer);
    };
  }, [navigate]);

  return <div>{status}</div>;
};

const ComingSoon = () => {
  const { view } = useParams();
  return <div>Coming Soon: {view}</div>;
};

const NotFound = () => {
  return <div>404 - Page Not Found</div>;
};

export default App;
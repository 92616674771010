import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Button } from './ui/Button';
import { XCircle, ArrowLeft } from 'lucide-react';

const CancelPage = () => {
  return (
    <div className="flex min-h-screen bg-[#f5f5f5] items-center justify-center p-4">
      <Card className="w-full max-w-md rounded-lg shadow-md bg-white">
        <CardHeader className="pb-2">
          <CardTitle className="text-2xl font-bold text-center text-black">Payment Cancelled</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <p className="text-gray-600 mb-6">
            Your payment has been cancelled. No charges were made.
          </p>
          <Link to="/dashboard" className="w-full">
            <Button className="bg-black text-white hover:bg-vibrant transition-colors w-full mb-4">
              <ArrowLeft className="mr-2 h-4 w-4" /> Return to Dashboard
            </Button>
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default CancelPage;
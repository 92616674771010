import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import OutfitGenerator from './OutfitGenerator';
import SavedOutfits from './SavedOutfits';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';

const OutfitGeneratorPage = () => {
  const [styleProfile, setStyleProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    fetchStyleProfile();
  }, []);

  const fetchStyleProfile = async () => {
    setIsLoading(true);
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    
    if (userError) {
      console.error('Error fetching user:', userError.message);
      setError('Failed to fetch user information.');
      setIsLoading(false);
      return;
    }

    if (user) {
      // Fetch the most recent color analysis scan
      const { data: colorData, error: colorError } = await supabase
        .from('user_color_analyses')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1); // Fetch only the most recent scan

      if (colorError) {
        console.error('Error fetching color analysis:', colorError.message);
        setError('Failed to fetch color analysis.');
        setIsLoading(false);
        return;
      }

      // Fetch the face shape analysis
      const { data: faceData, error: faceError } = await supabase
        .from('user_face_shape_analyses')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (faceError) {
        console.error('Error fetching face shape analysis:', faceError.message);
        setError('Failed to fetch face shape analysis.');
        setIsLoading(false);
        return;
      }

      // Fetch the body type analysis
      const { data: bodyData, error: bodyError } = await supabase
        .from('user_body_type_analyses')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (bodyError) {
        console.error('Error fetching body type analysis:', bodyError.message);
        setError('Failed to fetch body type analysis.');
        setIsLoading(false);
        return;
      }

      let incompleteAnalyses = [];
      if (!colorData || colorData.length === 0) incompleteAnalyses.push('Color Analysis');
      if (!faceData) incompleteAnalyses.push('Face Shape Analysis');
      if (!bodyData) incompleteAnalyses.push('Body Type Analysis');

      if (incompleteAnalyses.length > 0) {
        const missingText = incompleteAnalyses.join(', ');
        setError(`We're missing some key information to create your perfect outfit. Please complete: ${missingText}.`);
        setStyleProfile(null);
      } else {
        setStyleProfile({
          colorAnalysis: colorData[0],
          faceShape: faceData,
          bodyType: bodyData,
        });
      }
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <div className="p-4">Loading...</div>;
  }

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="outfit-generator-page p-4 md:p-6 lg:p-8">
      <h1 className="text-2xl font-bold mb-4">AI Outfit Generator</h1>
      <p className="text-gray-600 mb-6">Get personalized outfit recommendations based on your style profile</p>
      {styleProfile ? (
        <Tabs defaultValue="generate" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="generate">Generate Outfit</TabsTrigger>
            <TabsTrigger value="saved">Saved Outfits</TabsTrigger>
          </TabsList>
          <TabsContent value="generate">
            <OutfitGenerator styleProfile={styleProfile} />
          </TabsContent>
          <TabsContent value="saved">
            <SavedOutfits />
          </TabsContent>
        </Tabs>
      ) : (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
          <p className="font-bold mb-2">Let's Complete Your Style Profile!</p>
          <p>{error}</p>
          <p className="mt-2">Head to your dashboard to complete these quick and fun analyses. It'll help us create outfits that are perfect for you!</p>
          <button 
            onClick={goToDashboard}
            className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
          >
            Go to Dashboard
          </button>
        </div>
      )}
    </div>
  );
};

export default OutfitGeneratorPage;

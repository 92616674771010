import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Button } from './ui/Button';
import { Skeleton } from './ui/Skeleton';
import { Alert, AlertTitle, AlertDescription } from './ui/Alert';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { supabase } from './supabaseClient';
import { Scissors, ArrowLeft, AlertCircle, Sparkles, Calendar, Glasses, Palette, Diamond } from 'lucide-react';

const ReportComponent = () => {
    const { reportType } = useParams();
    const navigate = useNavigate();
    const [report, setReport] = useState(null);
    const [generatedDate, setGeneratedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const fetchReport = async () => {
            if (!isMounted) return;
            setIsLoading(true);
            setError(null);
            setReport(null); // Reset report when fetching new data
            try {
                const { data: { user }, error: sessionError } = await supabase.auth.getUser();
                
                if (sessionError) throw new Error('Failed to get user session');
                if (!user) throw new Error('No active session found');

                const { data, error } = await supabase
                    .from('user_reports')
                    .select('content, created_at')
                    .eq('report_type', reportType)
                    .eq('user_id', user.id)
                    .order('created_at', { ascending: false })
                    .limit(1);

                if (error) throw error;
                if (!data || data.length === 0) {
                    throw new Error('No report found');
                }

                if (isMounted) {
                    setReport(JSON.parse(data[0].content));
                    setGeneratedDate(new Date(data[0].created_at).toLocaleString());
                }
            } catch (error) {
                console.error('Error fetching report:', error);
                if (isMounted) {
                    setError(error.message);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchReport();

        return () => {
            isMounted = false;
        };
    }, [reportType]);

    const getReportIcon = () => {
        switch(reportType) {
            case 'hair_stylist': return <Scissors className="w-6 h-6 text-indigo-600" />;
            case 'eyewear_stylist': return <Glasses className="w-6 h-6 text-indigo-600" />;
            case 'makeup_artistry': return <Palette className="w-6 h-6 text-indigo-600" />;
            case 'jewelry_styling': return <Diamond className="w-6 h-6 text-indigo-600" />;
            default: return <Sparkles className="w-6 h-6 text-indigo-600" />;
        }
    };

    const renderReportContent = () => {
        if (!report) return <p>No report data available.</p>;

        switch (reportType) {
            case 'hair_stylist':
                return (
                    <Tabs defaultValue="color" className="w-full">
                        <TabsList className="grid w-full grid-cols-5 mb-6">
                            <TabsTrigger value="color">Color</TabsTrigger>
                            <TabsTrigger value="style">Style</TabsTrigger>
                            <TabsTrigger value="tips">Tips</TabsTrigger>
                            <TabsTrigger value="seasonal">Seasonal</TabsTrigger>
                            <TabsTrigger value="accessories">Accessories</TabsTrigger>
                        </TabsList>
                        <TabsContent value="color">
                            <h3 className="text-xl font-semibold mb-4">Hair Color Recommendations</h3>
                            {Array.isArray(report.hair_color_recommendations) && report.hair_color_recommendations.length > 0 ? 
                                report.hair_color_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.color}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Technique: {rec.technique}</p>
                                    </Card>
                                ))
                                : <p>No hair color recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="style">
                            <h3 className="text-xl font-semibold mb-4">Haircut & Style Recommendations</h3>
                            {Array.isArray(report.haircut_style_recommendations) && report.haircut_style_recommendations.length > 0 ? 
                                report.haircut_style_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.style}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Variations: {rec.variations}</p>
                                    </Card>
                                ))
                                : <p>No haircut and style recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="tips">
                            <h3 className="text-xl font-semibold mb-4">Styling Tips</h3>
                            {Array.isArray(report.styling_tips) && report.styling_tips.length > 0 ? 
                                report.styling_tips.map((tip, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{tip.tip}</h4>
                                        <p className="text-gray-600 mb-2">{tip.description}</p>
                                        <p className="text-sm text-indigo-600">Recommended Product: {tip.recommended_product}</p>
                                    </Card>
                                ))
                                : <p>No styling tips available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="seasonal">
                            <h3 className="text-xl font-semibold mb-4">Seasonal Adaptations</h3>
                            {report.seasonal_adaptations ? (
                                <>
                                    <Card className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">Spring/Summer</h4>
                                        <p className="text-gray-600">{report.seasonal_adaptations.spring_summer}</p>
                                    </Card>
                                    <Card className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">Fall/Winter</h4>
                                        <p className="text-gray-600">{report.seasonal_adaptations.fall_winter}</p>
                                    </Card>
                                </>
                            ) : <p>No seasonal adaptations available.</p>}
                        </TabsContent>
                        <TabsContent value="accessories">
                            <h3 className="text-xl font-semibold mb-4">Hair Accessories</h3>
                            {Array.isArray(report.hair_accessories) && report.hair_accessories.length > 0 ? 
                                report.hair_accessories.map((acc, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{acc.item}</h4>
                                        <p className="text-gray-600">{acc.description}</p>
                                    </Card>
                                ))
                                : <p>No hair accessory recommendations available.</p>
                            }
                        </TabsContent>
                    </Tabs>
                );
            case 'eyewear_stylist':
                return (
                    <Tabs defaultValue="face_shape" className="w-full">
                        <TabsList className="grid w-full grid-cols-4 mb-6">
                            <TabsTrigger value="face_shape">Face Shape</TabsTrigger>
                            <TabsTrigger value="color">Color</TabsTrigger>
                            <TabsTrigger value="frames">Frames</TabsTrigger>
                            <TabsTrigger value="tips">Tips</TabsTrigger>
                        </TabsList>
                        <TabsContent value="face_shape">
                            <h3 className="text-xl font-semibold mb-4">Face Shape Analysis</h3>
                            <p className="text-gray-600">{report.face_shape_analysis || 'No face shape analysis available.'}</p>
                        </TabsContent>
                        <TabsContent value="color">
                            <h3 className="text-xl font-semibold mb-4">Color Recommendations</h3>
                            {Array.isArray(report.color_recommendations) && report.color_recommendations.length > 0 ? 
                                <ul className="list-disc pl-5">
                                    {report.color_recommendations.map((color, index) => (
                                        <li key={index} className="text-gray-600 mb-2">{color}</li>
                                    ))}
                                </ul>
                                : <p>No color recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="frames">
                            <h3 className="text-xl font-semibold mb-4">Frame Recommendations</h3>
                            {Array.isArray(report.frame_recommendations) && report.frame_recommendations.length > 0 ? 
                                report.frame_recommendations.map((frame, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{frame.frame_style}</h4>
                                        <p className="text-gray-600 mb-2">{frame.description}</p>
                                        <p className="text-sm text-indigo-600">Suitable for: {frame.suitable_for}</p>
                                    </Card>
                                ))
                                : <p>No frame recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="tips">
                            <h3 className="text-xl font-semibold mb-4">Styling Tips</h3>
                            {Array.isArray(report.styling_tips) && report.styling_tips.length > 0 ? 
                                <ul className="list-disc pl-5">
                                    {report.styling_tips.map((tip, index) => (
                                        <li key={index} className="text-gray-600 mb-2">{tip}</li>
                                    ))}
                                </ul>
                                : <p>No styling tips available.</p>
                            }
                        </TabsContent>
                    </Tabs>
                );
            case 'makeup_artistry':
                return (
                    <Tabs defaultValue="color_season" className="w-full">
                        <TabsList className="grid w-full grid-cols-5 mb-6">
                            <TabsTrigger value="color_season">Color Season</TabsTrigger>
                            <TabsTrigger value="skin_tone">Skin Tone</TabsTrigger>
                            <TabsTrigger value="eye_makeup">Eye Makeup</TabsTrigger>
                            <TabsTrigger value="lip_makeup">Lip Makeup</TabsTrigger>
                            <TabsTrigger value="face_makeup">Face Makeup</TabsTrigger>
                        </TabsList>
                        <TabsContent value="color_season">
                            <h3 className="text-xl font-semibold mb-4">Color Season Analysis</h3>
                            <p className="text-gray-600">{report.color_season_analysis || 'No data available'}</p>
                        </TabsContent>
                        <TabsContent value="skin_tone">
                            <h3 className="text-xl font-semibold mb-4">Skin Tone Recommendation</h3>
                            <p className="text-gray-600">{report.skin_tone_recommendation || 'No data available'}</p>
                        </TabsContent>
                        <TabsContent value="eye_makeup">
                            <h3 className="text-xl font-semibold mb-4">Eye Makeup Recommendations</h3>
                            {Array.isArray(report.eye_makeup_recommendations) && report.eye_makeup_recommendations.length > 0 ? 
                                report.eye_makeup_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.product}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Shade: {rec.shade_recommendation}</p>
                                    </Card>
                                ))
                                : <p>No eye makeup recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="lip_makeup">
                            <h3 className="text-xl font-semibold mb-4">Lip Makeup Recommendations</h3>
                            {Array.isArray(report.lip_makeup_recommendations) && report.lip_makeup_recommendations.length > 0 ? 
                                report.lip_makeup_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.product}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Shade: {rec.shade_recommendation}</p>
                                    </Card>
                                ))
                                : <p>No lip makeup recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="face_makeup">
                            <h3 className="text-xl font-semibold mb-4">Face Makeup Recommendations</h3>
                            {Array.isArray(report.face_makeup_recommendations) && report.face_makeup_recommendations.length > 0 ? 
                                report.face_makeup_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.product}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Shade: {rec.shade_recommendation}</p>
                                    </Card>
                                ))
                                : <p>No face makeup recommendations available.</p>
                            }
                        </TabsContent>
                    </Tabs>
                );
                case 'jewelry_styling':
                return (
                    <Tabs defaultValue="body_type" className="w-full">
                        <TabsList className="grid w-full grid-cols-6 mb-6">
                            <TabsTrigger value="body_type">Body Type</TabsTrigger>
                            <TabsTrigger value="color_season">Color Season</TabsTrigger>
                            <TabsTrigger value="necklaces">Necklaces</TabsTrigger>
                            <TabsTrigger value="earrings">Earrings</TabsTrigger>
                            <TabsTrigger value="bracelets">Bracelets</TabsTrigger>
                            <TabsTrigger value="rings">Rings</TabsTrigger>
                        </TabsList>
                        <TabsContent value="body_type">
                            <h3 className="text-xl font-semibold mb-4">Body Type Analysis</h3>
                            <p className="text-gray-600">{report.body_type_analysis || 'No body type analysis available.'}</p>
                        </TabsContent>
                        <TabsContent value="color_season">
                            <h3 className="text-xl font-semibold mb-4">Color Season Analysis</h3>
                            <p className="text-gray-600">{report.color_season_analysis || 'No color season analysis available.'}</p>
                        </TabsContent>
                        <TabsContent value="necklaces">
                            <h3 className="text-xl font-semibold mb-4">Necklace Recommendations</h3>
                            {Array.isArray(report.necklace_recommendations) && report.necklace_recommendations.length > 0 ? 
                                report.necklace_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.item}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Metal: {rec.metal_recommendation}</p>
                                        {rec.stone_recommendation && (
                                            <p className="text-sm text-indigo-600">Stone: {rec.stone_recommendation}</p>
                                        )}
                                    </Card>
                                ))
                                : <p>No necklace recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="earrings">
                            <h3 className="text-xl font-semibold mb-4">Earring Recommendations</h3>
                            {Array.isArray(report.earring_recommendations) && report.earring_recommendations.length > 0 ? 
                                report.earring_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.item}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Metal: {rec.metal_recommendation}</p>
                                        {rec.stone_recommendation && (
                                            <p className="text-sm text-indigo-600">Stone: {rec.stone_recommendation}</p>
                                        )}
                                    </Card>
                                ))
                                : <p>No earring recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="bracelets">
                            <h3 className="text-xl font-semibold mb-4">Bracelet Recommendations</h3>
                            {Array.isArray(report.bracelet_recommendations) && report.bracelet_recommendations.length > 0 ? 
                                report.bracelet_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.item}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Metal: {rec.metal_recommendation}</p>
                                        {rec.stone_recommendation && (
                                            <p className="text-sm text-indigo-600">Stone: {rec.stone_recommendation}</p>
                                        )}
                                    </Card>
                                ))
                                : <p>No bracelet recommendations available.</p>
                            }
                        </TabsContent>
                        <TabsContent value="rings">
                            <h3 className="text-xl font-semibold mb-4">Ring Recommendations</h3>
                            {Array.isArray(report.ring_recommendations) && report.ring_recommendations.length > 0 ? 
                                report.ring_recommendations.map((rec, index) => (
                                    <Card key={index} className="mb-4 p-4">
                                        <h4 className="font-semibold text-lg">{rec.item}</h4>
                                        <p className="text-gray-600 mb-2">{rec.description}</p>
                                        <p className="text-sm text-indigo-600">Metal: {rec.metal_recommendation}</p>
                                        {rec.stone_recommendation && (
                                            <p className="text-sm text-indigo-600">Stone: {rec.stone_recommendation}</p>
                                        )}
                                    </Card>
                                ))
                                : <p>No ring recommendations available.</p>
                            }
                        </TabsContent>
                    </Tabs>
                );
            default:
                return <p>Report type not recognized.</p>;
        }
    };

    if (isLoading) {
        return (
            <Card className="w-full max-w-4xl mx-auto mt-8">
                <CardHeader>
                    <Skeleton className="h-8 w-3/4" />
                </CardHeader>
                <CardContent>
                    <Skeleton className="h-4 w-full mb-2" />
                    <Skeleton className="h-4 w-5/6 mb-2" />
                    <Skeleton className="h-4 w-4/6" />
                </CardContent>
            </Card>
        );
    }

    if (error) {
        return (
            <Card className="w-full max-w-4xl mx-auto mt-8">
                <CardContent>
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                    <Button 
                        onClick={() => navigate('/dashboard')} 
                        className="mt-4"
                        variant="outline"
                    >
                        <ArrowLeft className="mr-2 h-4 w-4" /> Return to Dashboard
                    </Button>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="w-full max-w-4xl mx-auto mt-8 shadow-lg">
            <CardHeader className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        {getReportIcon()}
                        <CardTitle className="text-2xl font-bold">
                            {reportType.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} Report
                        </CardTitle>
                    </div>
                    <Button 
                        onClick={() => navigate('/dashboard')} 
                        variant="secondary"
                        size="sm"
                    >
                        <ArrowLeft className="mr-2 h-4 w-4" /> Back
                    </Button>
                </div>
            </CardHeader>
            <CardContent className="p-6">
                {generatedDate && (
                    <div className="bg-gray-100 p-4 rounded-lg mb-6">
                        <div className="flex items-center">
                            <Calendar className="w-5 h-5 mr-2 text-gray-600" />
                            <span className="text-sm font-medium">Generated on: {generatedDate}</span>
                        </div>
                    </div>
                )}
                {renderReportContent()}
            </CardContent>
        </Card>
    );
};

export default ReportComponent;